//#region IMPORT

import { Component} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
import { SessionService } from "src/app/services/session.service";
import { TicketService } from "src/app/services/ticket.service";
import { BaseAuthourizeDetailComponent } from "../../bases/baseauthourizedetail.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-melisha",
	templateUrl: "./melisha.component.html",
	styleUrls: ["./melisha.component.sass"]
})

//#endregion


//#region CLASS

export class MelishaComponent extends BaseAuthourizeDetailComponent
{

	//#region CONSTRUCTOR

	constructor(routeActivated: ActivatedRoute, location: Location, private _serviceTicket: TicketService, serviceSession: SessionService, public router: Router)
	{
		super(routeActivated, location, serviceSession, router);
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		const elementBotikaWidget = document.getElementById("BotikaWidget") as HTMLElement;
		const firstElement = document.getElementById("BotikaWidget")?.firstChild as HTMLElement;
		const widgetIframe = document.getElementById("widget_iframe") as HTMLElement;

		const divHeaderElement = document.createElement("div");
		divHeaderElement.setAttribute("id", "divHeaderMelisha");

		const headerElement = document.createElement("header");
		headerElement.setAttribute("class", "HeaderContainerProduct");

		const divNavigation = document.createElement("div");
		divNavigation.setAttribute("class", "DivNavigation");

		const imageMelisha = document.createElement("img");
		imageMelisha.setAttribute("src", "../../../../assets/icons/sakina/icon_back.svg");
		imageMelisha.addEventListener("click", this.navigateToBack.bind(this));

		const imageBack = document.createElement("img");
		imageBack.setAttribute("src", "../../../../assets/icons/sakina/icon_home.svg");
		imageBack.addEventListener("click", this.navigateToHome.bind(this));

		const divContainer = document.createElement("div");
		divContainer.setAttribute("class", "DivContainerTitle");

		const paragraphMelisha = document.createElement("p");
		paragraphMelisha.innerText = "";

		divContainer.appendChild(paragraphMelisha);

		divNavigation.appendChild(imageMelisha);
		divNavigation.appendChild(divContainer);
		divNavigation.appendChild(imageBack);

		headerElement.appendChild(divNavigation);

		divHeaderElement.appendChild(headerElement);

		if (!localStorage.getItem("Refresh"))
		{
			localStorage.setItem("Refresh", "no reload");
			location.reload();
		}
		else
		{
			elementBotikaWidget.style.display = "flex";
			localStorage.removeItem("Refresh");
		}

		if(firstElement.id !== divHeaderElement.id)
		{
			elementBotikaWidget.insertBefore(divHeaderElement, widgetIframe);
		}

		this._router.events.subscribe(
			(event) =>
			{
				if ( event instanceof NavigationEnd )
				{
					elementBotikaWidget.style.display = "none";
				}
			});
	}

	//#endregion


	//#region NAVIGATION

	navigateToBack()
	{
		alert("History chat akan terhapus apabila chatbot ditutup");
		this._location.back();
	}

	navigateToHome()
	{
		alert("History chat akan terhapus apabila chatbot ditutup");
		this._router.navigate(["home"]);
	}

	//#endregion
}

//#endregion

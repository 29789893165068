//#region IMPORT

import { CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxImageCompressService } from "ngx-image-compress";
import { WebcamModule } from "ngx-webcam";
import { GoogleMap, GoogleMapsModule } from "@angular/google-maps";
import { CustomcurrencyDirective } from "../functions/directives/customcurrency.directive";
import { PipeModule } from "../functions/pipes/pipe.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./root/app.component";
import { HomeComponent } from "./home/home.component";
import { CardComponent } from "./includes/card/card.component";
import { CustomerCareComponent } from "./customercare/customercare/customercare.component";
import { PurchaseComponent } from "./purchase/purchase/purchase.component";
import { MelishaComponent } from "./customercare/melisha/melisha.component";
import { TicketingComponent } from "./customercare/ticketing/ticketing.component";
import { PurchaseModule } from "./purchase/purchase.module";
import { CustomerCareModule } from "./customercare/customercare.module";
import { ClaimComponent } from "./claim/claim.component";
import { WorkshopComponent } from "./customercare/workshop/workshop.component";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
	[
		AppComponent,
		CustomcurrencyDirective,
		HomeComponent,
		CardComponent,
		CustomerCareComponent,
		PurchaseComponent,
		MelishaComponent,
		TicketingComponent,
		ClaimComponent,
		WorkshopComponent
	],
		imports:
	[
		BrowserModule,
		AppRoutingModule,
		FormsModule,
		HttpClientModule,
		PipeModule,
		BrowserAnimationsModule,
		MatNativeDateModule,
		MatInputModule,
		MatSliderModule,
		MatDatepickerModule,
		MatDialogModule,
		ReactiveFormsModule,
		WebcamModule,
		PurchaseModule,
		CustomerCareModule,
		GoogleMapsModule,
	],
		providers: [GoogleMap, CurrencyPipe, NgxImageCompressService, DecimalPipe, DatePipe, {
			provide: MatDialogRef,
			useValue: {}
		},],
		bootstrap: [AppComponent]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion

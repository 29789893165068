<div class="DivBodyGeneral DivContainerPartnershipWorkshop">
	<app-navigation
		[_stringTitle]="_stringConstant.STRING_VALUE_TITLE_NAVIGATIONWORKSHOP"
		[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONWORKSHOP">
	</app-navigation>

	<section class="SectionForm SectionFormWorkshop">
		<div class="DivForm">
			<fieldset>
				<div class="DivFormVerticalContainer">
					<label for="textPartnershipWorkshopCity">Pilih Kota</label>
					<select id="modelPartnershipWorkshopCity" name="modelPartnershipWorkshopCity" [ngClass]="{selectPlaceholder: _modelPartnershipWorkshopCity.CityName === undefined}" [(ngModel)]="_modelPartnershipWorkshopCity.CityName" (change)="setPartnershipWorkshipByAttributes(_modelPartnershipWorkshopCity.CityName!)">
						<option [ngValue]="undefined" selected>Semua</option>
						<ng-container *ngFor="let modelPartnershipWorkshopCity of _arrayModelPartnershipWorkshopCity">
							<option [value]="modelPartnershipWorkshopCity.CityName"  >{{ modelPartnershipWorkshopCity.CityName }}</option>
						</ng-container>
					</select>

					<label for="textPartnershipWorkshop">Pilih Bengkel</label>
					<select id="modelPartnershipWorkshop" name="modelPartnershipWorkshopCity" [ngClass]="{selectPlaceholder: _modelPartnershipWorkshopForResponsive === undefined}" [(ngModel)]="_modelPartnershipWorkshopForResponsive" (change)="showPartnershipWorkshopForResponsive()">
						<option [ngValue]="undefined" selected>Pilih Bengkel</option>
						<ng-container *ngFor="let modelPartnershipWorkshop of _arrayModelPartnershipWorkshop">
							<option [ngValue]="modelPartnershipWorkshop">{{ modelPartnershipWorkshop.Name }}</option>
						</ng-container>
					</select>
				</div>
			</fieldset>
		</div>
	</section>

	<ng-container *ngIf="_booleanShowMaps">
		<section class="SectionMaps">
			<div class="DivContainerMaps">
				<google-map [options]="mapOptions" height="600px" width="100%">
					<map-marker #markerElem="mapMarker"
						[position]="{ lat: this._modelPartnershipWorkshop.Latitude, lng: this._modelPartnershipWorkshop.Longitude }"
						(mapClick)="openInfoWindow(markerElem, 0)">

						<map-info-window>
							<a> {{ this._modelPartnershipWorkshop.Name }}</a>
							<p>{{ this._modelPartnershipWorkshop.Address }}</p>
						</map-info-window>
					</map-marker>
				</google-map>
			</div>
			<div class="DivContainerMapsInformation">
				<div class="DivHorizontalMapsInformation">
					<img class="ImageAddress" [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGEMAPSADDRESS">
					<label for="labelInformation" class="LabelInformation">{{_modelPartnershipWorkshop.Address}}</label>
				</div>
				<div class="DivHorizontalMapsInformation">
					<img class="ImageAddress" [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGEMAPSCONTACT">
					<label for="labelInformation" class="LabelInformation">{{_modelPartnershipWorkshop.Email}}</label>
				</div>
				<div class="DivHorizontalMapsInformation">
					<img class="ImageAddress" [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGEMAPSCITY">
					<label for="labelInformation" class="LabelInformation">{{_modelPartnershipWorkshop.CityName}}</label>
				</div>
			</div>
		</section>
	</ng-container>
</div>



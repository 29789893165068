//#region IMPORT

import { Component } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-claim",
	templateUrl: "./claim.component.html",
	styleUrls: ["./claim.component.sass"]
})

//#endregion


//#region CLASS

export class ClaimComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	_stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion

}

//#endregion

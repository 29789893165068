<div class="DivBodyGeneral">
	<header class="HeaderContainerProduct">
		<app-navigation
			[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONPURCHASE"
			[_booleanTitle]="false"
			[_booleanNavigationProduct]="true"
			[_booleanCustomNavigateBack]="true"
			(_buttonClicked)="navigateBack()">
		</app-navigation>
		<img class="ImgBackground" [src]="_stringConstant.STRING_VALUE_SOURCE_HEADEROTO">
		<div class="DivContainerProduct">
			<img [src]="_stringConstant.STRING_VALUE_SOURCE_ICONPURCHASEOTO">
			<h1>OTO</h1>
		</div>
	</header>

	<ng-container *ngIf="_booleanShowQR === false">
		<section class="SectionCardList SectionWithButton SectionProduct">
			<div class="DivFormVerticalContainer">
				<h2>Deskripsi</h2>
				<p>Melindungi kendaraan bermotor roda 4 (empat) Anda dari kehilangan dan kerusakan yang disebabkan oleh risiko tidak terduga (yaitu: tabrakan, benturan, terbalik, tergilincir atau terperosok, perbuatan jahat, pencurian yang diikuti dengan kekerasan atau ancaman) dan perluasan yang lainnya.</p>
				<h2>Harga Pertanggungan</h2>
				<div class="DivPriceValidation">
					<ul>
						<li>Harga Pertanggungan Maksimal s.d. Rp2.000.000.000</li>
						<li>Harga Perlengkapan Minimal Rp100.000 dan Maksimal 10% dari Total Harga Kendaraan (Jika diisi)</li>
					</ul>
				</div>
				<h2>Paket</h2>
				<ng-container *ngFor="let modelPackage of _arrayModelPackage; let numberIndex = index">
					<app-cardproduct class="TransitionLineUpFourth"
						[_stringTitle]="getTitlePackage(modelPackage)"
						[_stringSource]="getSourcePackage(modelPackage)"
						[_modelPackage]="modelPackage"
						[_booleanFirstOfType]="getTypeByIndex(numberIndex)">
					</app-cardproduct>
				</ng-container>
			</div>
		</section>

		<app-stickbutton
			[_stringButtonTitle]="'Lanjutkan Pembelian'"
			[_stringCardTitle]="'Klik disini untuk membeli'"
			[_booleanCardTitle]="true"
			(_buttonClicked)="setShowQR()">
		</app-stickbutton>
	</ng-container>

	<ng-container *ngIf="_booleanShowQR === true">
		<section class="SectionCardList SectionShowQR">
			<p>Scan QR dibawah ini</p>
			<img [src]="getSourceImageQRURL(_stringConstant.STRING_VALUE_SOURCE_OTO)">
			<p>atau salin link dibawah ini</p>
			<label>{{getStringURL(_stringConstant.STRING_VALUE_SOURCE_OTO)}}</label>
		</section>
		<app-footer></app-footer>
	</ng-container>
</div>

//#region IMPORT

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Location } from "@angular/common";
import { SessionService } from "src/app/services/session.service";
import { Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.sass"]
})

//#endregion


//#region CLASS

export class NavigationComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@Input() _stringTitle: string;
	@Input() _stringSource: string;
	@Input() _booleanTitle: boolean;
	@Input() _booleanNavigationProduct: boolean;
	@Input() _booleanCustomNavigateBack: boolean;

	@Output() _buttonClicked = new EventEmitter<string>();

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);

		this._stringTitle = "";
		this._stringSource = "";
		this._booleanTitle = true;
		this._booleanNavigationProduct = false;
		this._booleanCustomNavigateBack = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region NAVIGATION

	navigateToBack()
	{
		this._location.back();
	}

	navigateToHome()
	{
		this._router.navigate(["home"]);
	}

	//#endregion


	//#region FUNCTION

	onClick()
	{
		this._buttonClicked.emit("Button clicked in child component");
	}

	//#endregion

}

//#endregion

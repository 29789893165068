//#region IMPORT

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StickbuttonComponent } from "../includes/stickbutton/stickbutton.component";
import { CustomerCareRoutingModule } from "./customercare-routing.module";

//#endregion


//#region NGMODULE
@NgModule
(
	{
		declarations:
	[
		StickbuttonComponent
	],
		imports:
	[
		CommonModule,
		CustomerCareRoutingModule
	],
		exports:
	[
		StickbuttonComponent
	]
	}
)

//#endregion


//#region CLASS

export class CustomerCareModule
{

}

//#endregion

//#region IMPORT

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-card",
	templateUrl: "./card.component.html",
	styleUrls: ["./card.component.sass"]
})

//#endregion


//#region CLASS

export class CardComponent implements OnInit
{

	//#region DECLARATION

	@Input() _stringTitle: string;
	@Input() _stringSubTitle: string;
	@Input() _stringSource: string;
	@Input() _booleanBackgroundCard: boolean;
	@Input() _booleanLastOfType: boolean;
	@Input() _booleanMelisha: boolean;

	@Output() _buttonClicked = new EventEmitter<string>();

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
  	this._stringTitle = "";
  	this._stringSubTitle = "";
  	this._stringSource = "";
  	this._booleanBackgroundCard = false;
  	this._booleanLastOfType = false;
  	this._booleanMelisha = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION

	onClick()
	{
  	this._buttonClicked.emit("Button clicked in child component");
	}

	//#endregion

}

//#endregion

//#region IMPORT

import { NgModule } from "@angular/core";
import { ConvertByteForPreview, ConvertEmptyToDash, ConvertExtensionToIcon, ConvertNoDivisionToCustomer, ConvertSeparateEnumSpace, ConvertToDateMedium, ConvertToDateShort, CurrencyValue, InitiateWithoutUTC, ConvertToCapitalEachWord } from "./converter.pipe";
import { CustomcurrencyPipe } from "./customcurrency.pipe";
import { ConvertToBroadcastURL, ConvertToProductURL, ConvertToUserURL } from "./hyperlink.pipe";
import { TrimShort } from "./trim.pipe";

//#endregion


//#region MODULE

@NgModule
(
	{
		declarations:
	[
		ConvertToProductURL,
		ConvertToDateMedium,
		ConvertToDateShort,
		ConvertEmptyToDash,
		InitiateWithoutUTC,
		CurrencyValue,
		ConvertSeparateEnumSpace,
		ConvertNoDivisionToCustomer,
		TrimShort,
		ConvertByteForPreview,
		ConvertExtensionToIcon,
		ConvertToBroadcastURL,
		ConvertToUserURL,
		CustomcurrencyPipe,
		ConvertToCapitalEachWord
	],
		exports:
	[
		ConvertToProductURL,
		ConvertToDateMedium,
		ConvertToDateShort,
		ConvertEmptyToDash,
		InitiateWithoutUTC,
		CurrencyValue,
		ConvertSeparateEnumSpace,
		ConvertNoDivisionToCustomer,
		TrimShort,
		ConvertByteForPreview,
		ConvertExtensionToIcon,
		ConvertToBroadcastURL,
		ConvertToUserURL,
		CustomcurrencyPipe,
		ConvertToCapitalEachWord
	]
	}
)

//#endregion


//#region CLASS

export class PipeModule { }

//#endregion
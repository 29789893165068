<div class="DivBodyGeneral">
	<app-navigation
		[_stringTitle]="_stringConstant.STRING_VALUE_TITLE_NAVIGATIONPURCHASE"
		[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONPURCHASE">
	</app-navigation>

	<section class="SectionCardList">
		<!-- PRODUCT ASRI -->
		<app-card class="TransitionLineUpFirst"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_ASRI"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_ASRI"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_ASRI"
			(_buttonClicked)="navigateToPurchaseProductAsri()">
		</app-card>

		<!-- PRODUCT OTO -->
		<app-card class="TransitionLineUpSecond"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_OTO"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_OTO"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_OTO"
			(_buttonClicked)="navigateToPurchaseProductOto()">
		</app-card>

		<!-- PRODUCT PERSONAL ACCIDENT -->
		<app-card class="TransitionLineUpThird"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_PERSONALACCIDENT"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_PERSONALACCIDENT"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_PERSONALACCIDENT"
			(_buttonClicked)="navigateToPurchaseProductPersonalAccident()">
		</app-card>

		<!-- PRODUCT BICYCLE -->
		<app-card class="TransitionLineUpFourth"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_BICYCLE"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_BICYCLE"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_BICYCLE"
			(_buttonClicked)="navigateToPurchaseProductBicycle()"
			[_booleanLastOfType]="true">
		</app-card>
	</section>

	<app-footer></app-footer>
</div>

//#region IMPORT

import { Router } from "@angular/router";
import { PartialObserver } from "rxjs";
import { SessionService } from "src/app/services/session.service";
import { ConvertToBroadcastURL } from "src/app/functions/pipes/hyperlink.pipe";
import { UserModel } from "../../models/user.model";
import { CustomerModel } from "../../models/customer.model";
import { environment } from "../../../environments/environment";
import { StringConstant } from "../../constants/string.constant";
import { BasePublicComponent } from "./basepublic.component";

//#endregion


//#region CLASS

export class BaseAuthourizeComponent extends BasePublicComponent
{
	//#region DECLARATION

	public _modelUserSignIn: UserModel;
	public _modelCustomerSignIn: CustomerModel;

	public _pipeConvertToBroadcastURL = new ConvertToBroadcastURL();

	latitude: number = -1.605328;
	longitude: number = 117.451067;
	type = "SATELLITE";
	zoom: number = 15;
	minzoom: number = 2;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._modelUserSignIn = new UserModel();
		this._modelCustomerSignIn = new CustomerModel();

		const observerPartialModelUserSignIn: PartialObserver<UserModel> =
		{
			next: (modelUser: UserModel) => { this._modelUserSignIn = modelUser; },
			error: () => { },
			complete: () => { }
		};

		const observerPartialModelCustomerSignIn: PartialObserver<CustomerModel> =
		{
			next: (modelCustomerModel: CustomerModel) => { this._modelCustomerSignIn = modelCustomerModel; },
			error: () => { },
			complete: () => { }
		};

		this._serviceSession._modelUserSignIn.subscribe(observerPartialModelUserSignIn);
		this._serviceSession._modelCustomerSignIn.subscribe(observerPartialModelCustomerSignIn);
	}

	//#endregion


	//#region FUNCTION

	protected signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
		this._serviceSession.clearSessionQuery();

		// this._router.navigate(["signin"]);
	}

	public getSourceImageQRURL(stringSource: string): string
	{
		if(environment.BUILD === StringConstant.STRING_VALUE_BUILD_STAGING)
		{
			return "../../../../assets/photos/sakina/staging/image_qr_" + stringSource + ".png";
		}
		else if(environment.BUILD === StringConstant.STRING_VALUE_BUILD_PRODUCTION)
		{
			return "../../../../assets/photos/sakina/production/image_qr_" + stringSource + ".png";
		}
		else
		{
			return "../../../../assets/photos/sakina/development/image_qr_" + stringSource + ".png";
		}
	}

	public getStringURL(stringSource: string, booleanClaim: boolean = false): string
	{
		if(environment.BUILD === StringConstant.STRING_VALUE_BUILD_STAGING)
		{
			if(booleanClaim)
			{
				return StringConstant.STRING_VALUE_SOURCE_BITLY+"staging"+stringSource;
			}
			else
			{
				return StringConstant.STRING_VALUE_SOURCE_BITLY+"stagingproduct"+stringSource;
			}
		}
		else if(environment.BUILD === StringConstant.STRING_VALUE_BUILD_PRODUCTION)
		{
			if(booleanClaim)
			{
				return StringConstant.STRING_VALUE_SOURCE_BITLY+"KLAIMAsuransi";
			}
			else
			{
				if(stringSource === StringConstant.STRING_VALUE_SOURCE_ASRI)
				{
					return StringConstant.STRING_VALUE_SOURCE_BITLY+"AsuransiASRI";
				}
				else if(stringSource === StringConstant.STRING_VALUE_SOURCE_BICYCLE)
				{
					return StringConstant.STRING_VALUE_SOURCE_BITLY+"AsuransiSEPEDA";
				}
				else if(stringSource === StringConstant.STRING_VALUE_SOURCE_PERSONALACCIDENT)
				{
					return StringConstant.STRING_VALUE_SOURCE_BITLY+"AsuransiDIRI";
				}
				else
				{
					return StringConstant.STRING_VALUE_SOURCE_BITLY+"AsuransiOTO";
				}
			}
		}
		else
		{
			if(booleanClaim)
			{
				return StringConstant.STRING_VALUE_SOURCE_BITLY+"development"+stringSource;
			}
			else
			{
				return StringConstant.STRING_VALUE_SOURCE_BITLY+"developmentproduct"+stringSource;
			}
		}
	}

	//#endregion


	//#region CHECK

	protected checkURL(stringURLPath: string): boolean
	{
		return this._router.url.indexOf(stringURLPath) > -1;
	}

	//#endregion
}

//#endregion

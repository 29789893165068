//#region IMPORT

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationComponent } from "../includes/navigation/navigation.component";
import { CustomerCareModule } from "../customercare/customercare.module";
import { CardproductComponent } from "../includes/cardproduct/cardproduct.component";
import { FooterComponent } from "../includes/footer/footer.component";
import { PurchaseRoutingModule } from "./purchase-routing.module";
import { AsriYearlyComponent } from "./asriyearly/asriyearly.component";
import { OtoYearlyComponent } from "./otoyearly/otoyearly.component";
import { PersonalAccidentYearlyComponent } from "./personalaccidentyearly/personalaccidentyearly.component";
import { BicycleYearlyComponent } from "./bicycleyearly/bicycleyearly.component";

//#endregion


//#region NGMODULE
@NgModule
(
	{
		declarations:
	[
		AsriYearlyComponent,
		OtoYearlyComponent,
		PersonalAccidentYearlyComponent,
		BicycleYearlyComponent,
		NavigationComponent,
		CardproductComponent,
		FooterComponent,
	],
		exports:
	[
		NavigationComponent,
		CardproductComponent,
		FooterComponent
	],
		imports:
	[
		CommonModule,
		PurchaseRoutingModule,
		CustomerCareModule
	]
	}
)

//#endregion


//#region CLASS

export class PurchaseModule
{

}

//#endregion

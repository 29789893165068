<div class="DivBodyGeneral">
	<app-navigation
		[_stringTitle]="_stringConstant.STRING_VALUE_TITLE_NAVIGATIONCUSTOMERCARE"
		[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONCUSTOMERCARE">
	</app-navigation>

	<section class="SectionCardList">
		<!-- Melisha -->

		<app-card class="TransitionLineUpFirst" id="divCardMelisha"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_MELISHA"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_MELISHA"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_MELISHA"
			[_booleanMelisha]="true"
			(_buttonClicked)="navigateToMelisha()">
		</app-card>

		<!-- Ticketing -->
		<app-card class="TransitionLineUpSecond"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_TICKETING"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_TICKETING"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_TICKETING"
			(_buttonClicked)="navigateToTicketing()">
		</app-card>

		<!-- Near Me -->
		<app-card class="TransitionLineUpThird"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_NEARME"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_NEARME"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_NEARME"
			(_buttonClicked)="navigateToNearMe()">
		</app-card>
	</section>

  	<app-footer></app-footer>
</div>

//#region IMPORT

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-stickbutton",
	templateUrl: "./stickbutton.component.html",
	styleUrls: ["./stickbutton.component.sass"]
})

//#endregion


//#region CLASS

export class StickbuttonComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@Input() _stringButtonTitle: string;
	@Input() _stringCardTitle: string;
	@Input() _booleanCardTitle: boolean;

	@Output() _buttonClicked = new EventEmitter<string>();

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._stringButtonTitle = "";
		this._stringCardTitle = "";
		this._booleanCardTitle = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION

	onClick()
	{
		this._buttonClicked.emit("Button clicked in child component");
	}

	//#endregion

}

//#endregion

<div class="DivBodyGeneral">
	<header class="HeaderContainerProduct">
		<app-navigation
			[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONPURCHASE"
			[_booleanTitle]="false"
			[_booleanNavigationProduct]="true"
			[_booleanCustomNavigateBack]="true"
			(_buttonClicked)="navigateBack()">
		</app-navigation>
		<img class="ImgBackground" [src]="_stringConstant.STRING_VALUE_SOURCE_HEADERASRI">
		<div class="DivContainerProduct">
			<img [src]="_stringConstant.STRING_VALUE_SOURCE_ICONPURCHASEASRI">
			<h1>ASRI</h1>
		</div>
	</header>

	<ng-container *ngIf="_booleanShowQR === false">
		<section class="SectionCardList SectionWithButton SectionProduct">
			<div class="DivFormVerticalContainer">
				<h2>Deskripsi</h2>
				<p>Lindungi properti dan tempat tinggal Anda dari kehilangan dan kerusakan yang disebabkan oleh risiko yang tidak terduga (yaitu: kebakaran, petir, ledakan, dampak pesawat jatuh dan asap) dan perluasan yang lainnya.</p>
				<h2>Harga Pertanggungan</h2>
				<div class="DivPriceValidation">
					<ul>
						<li>Harga Pertanggungan Maksimal s.d. Rp3.000.000.000</li>
						<li>Harga Perlengkapan Minimal Rp100.000 dan Maksimal 10% dari Total Harga Bangunan (Jika diisi)</li>
					</ul>
				</div>
				<h2>Paket</h2>
				<ng-container *ngFor="let modelPackage of _arrayModelPackage; let numberIndex = index">
					<app-cardproduct class="TransitionLineUpFourth"
						[_stringTitle]="getTitlePackage(modelPackage)"
						[_stringSource]="getSourcePackage(modelPackage)"
						[_modelPackage]="modelPackage"
						[_booleanFirstOfType]="getTypeByIndex(numberIndex)">
					</app-cardproduct>
				</ng-container>
			</div>
		</section>

		<app-stickbutton
			[_stringButtonTitle]="'Lanjutkan Pembelian'"
			[_stringCardTitle]="'Klik disini untuk membeli'"
			[_booleanCardTitle]="true"
			(_buttonClicked)="setShowQR()">
		</app-stickbutton>
	</ng-container>

	<ng-container *ngIf="_booleanShowQR === true">
		<section class="SectionCardList SectionShowQR">
			<p>Scan QR dibawah ini</p>
			<img [src]="getSourceImageQRURL(_stringConstant.STRING_VALUE_SOURCE_ASRI)">
			<p>atau salin link dibawah ini</p>
			<label>{{getStringURL(_stringConstant.STRING_VALUE_SOURCE_ASRI)}}</label>
		</section>
		<app-footer></app-footer>
	</ng-container>
</div>

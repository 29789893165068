//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { PackageModel } from "src/app/models/package.model";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-personalaccidentyearly",
	templateUrl: "./personalaccidentyearly.component.html",
	styleUrls: ["./personalaccidentyearly.component.sass"]
})

//#endregion


//#region CLASS

export class PersonalAccidentYearlyComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelPackage: PackageModel;

	public _stringConstant = StringConstant;

	public _booleanShowQR: boolean;

	public _arrayModelPackage: Array<PackageModel> =
		[
			{
				Name: "Personal Accident",
				Code: "PA-BM",
				Description: "Nilai Pertanggungan Sebesar: Rp 100.000.000",
				listCoverage :
				[
					{ Text: "Kematian", Covered : true },
					{ Text: "Cacat tetap sebagian atau seluruh tubuh", Covered : true },
					{ Text: "Biaya perobatan atau pengobatan", Covered : true }
				]
			}
		];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);

		this._modelPackage = new PackageModel();

		this._booleanShowQR = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region NAVIGATION

	navigateBack()
	{
		if(this._booleanShowQR)
		{
			this._booleanShowQR = false;
		}
		else
		{
			this._location.back();
		}
	}

	//#endregion


	//#region SETTER

	setShowQR()
	{
		this._booleanShowQR = true;
	}

	//#endregion


	//#region GETTER

	public getSourcePackage(modelPackage: PackageModel): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTBM)
		{
			return "../../../../../assets/icons/sakina/icon_personal_accident_yearly.svg";
		}
		else
		{
			return "";
		}
	}

	public getTitlePackage(modelPackage): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_PERSONALACCIDENTBM)
		{
			return this._stringConstant.STRING_VALUE_TITLECARDLIST_PERSONALACCIDENT;
		}
		else
		{
			return "";
		}
	}

	public getTypeByIndex(numberIndex: number): boolean
	{
		if(numberIndex === 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion

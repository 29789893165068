<div class="DivBodyGeneral">
	<app-navigation
		[_stringTitle]="_stringConstant.STRING_VALUE_TITLE_NAVIGATIONTICKETING"
		[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONTICKETING">
	</app-navigation>

	<section class="SectionForm SectionWithButton">
		<div class="DivForm">
			<fieldset>
				<div class="DivFormVerticalContainer">
					<label for="textModelTicketingCustomerName">Nama*</label>
					<input type="text" id="modelTicketingCustomerName" name="modelTicketingCustomerName" [(ngModel)]="_modelTicketRequest.CustomerName" placeholder="Masukkan Nama" (keypress)="this._functionRegex.regexAlfabetWithSpace($event)">

					<label for="textModelTicketingTitle">Keluhan*</label>
					<input type="text" id="modelTicketingTitle" name="modelTicketingTitle" [(ngModel)]="_modelTicketRequest.Title" placeholder="Masukkan Keluhan" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)">

					<label for="textModelTicketingTopic">Topik*</label>
					<select id="modelTicketingTopic" name="modelTicketingTopic" [(ngModel)]="_modelTicketRequest.Topic" [ngClass]="{selectPlaceholder: _modelTicketRequest.Topic === undefined}">
						<option [ngValue]="undefined">Pilih Topik</option>
						<option [ngValue]="_enumTopicType.Information">Informasi</option>
						<option [ngValue]="_enumTopicType.Claim">Klaim</option>
						<option [ngValue]="_enumTopicType.Complaint">Komplain</option>
						<option [ngValue]="_enumTopicType.Others">Lainnya</option>
					</select>

					<label id="textModelTicketingDescription">Deskripsi*</label>
					<textarea  id="modelTicketingDescription" name="modelTicketingDescription" rows="10" [(ngModel)]="_modelTicketRequest.Description" placeholder="Masukkan Deskripsi" (keypress)="this._functionRegex.regexAlfanumericSpecialCharacter($event)"></textarea>

					<label for="textModelTicketingEmail">Email*</label>
					<input type="text" id="modelTicketingEmail" name="modelTicketingEmail" [(ngModel)]="_modelTicketRequest.CustomerEmail" placeholder="Masukkan Email" (keypress)="this._functionRegex.regexEmail($event)">

					<label for="textModelTicketingCustomerPhone">Nomor Handphone*</label>
					<div class="DivFormHorizontalContainer">
						<input type="text" name="modelTicketingPhoneNumberPrefix" id="modelTicketingPhoneNumberPrefix" value="+62" disabled>
						<input type="number" name="modelTicketingPhoneNumber" id="modelTicketingPhoneNumber" placeholder="Masukkan Nomor Handphone" [(ngModel)]="_stringNumberPhone" maxlength="13" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" (keypress)="this._functionRegex.regexNumber($event)">
					</div>
				</div>
			</fieldset>
		</div>
	</section>

	<app-stickbutton
		[_stringButtonTitle]="'Kirim'"
		(_buttonClicked)="callInsertTicketForCustomerPublic(this)">
	</app-stickbutton>

</div>



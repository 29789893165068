//#region CONSTANT

export class StringConstant
{
	//#region REGULUAR_EXPRESSION

	static STRING_REGULAREXPRESSION_PERSONNAME: string = "^[A-Za-z\\s]{1,}[\\.]{0," + "1}[A-Za-z\\s]{0,}$";
	static STRING_REGULAREXPRESSION_ALPHABETWITHOUTEMOJI: string = "[A-Za-z]*";
	static STRING_REGULAREXPRESSION_ALPHABET: string = "^[A-Za-z]";
	static STRING_REGULAREXPRESSION_NUMERIC: string = "^[0-9]*$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERIC: string = "^[a-zA-Z0-9]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHSPACE: string = "^[a-zA-Z0-9]";
	static STRING_REGULAREXPRESSION_EMAIL: string = "[a-zA-Z0-9+$\\+\\.\\_\\%\\-\\+]{1,256}\\@[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}(\\.[a-zA-Z0-9][a-zA-Z0-9\\-]{0,25})+$";
	static STRING_REGULAREXPRESSION_MOBILEPHONEWITHPLUS: string = "^[+]?[0-9]{10,15}$";
	static STRING_REGULAREXPRESSION_DATEISO8601: string = "[0-9]{4}-[0-9]{2}-[0-9]{1,2}";
	static STRING_REGULAREXPRESSION_CURRENCY: string = "[^0-9]";
	static STRING_REGULAREXPRESSION_HTMLTAG: string = "<[^>]+>";
	static STRING_REGULAREXPRESSION_ADDRESS: string = "^[a-zA-Z0-9./(),\s]+$";
	static STRING_REGULAREXPRESSION_EMAILFORBRIMO: string = "^[a-za-zA-Z0-9._%+-]+@[a-za-zA-Z0-9.-]+\\.[a-za-zA-Z]{2,4}$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTEMOJI: string = "^\\S[a-zA-Z0-9\\s]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHHYPEN: string = "^[a-zA-Z0-9-]+$";
	static STRING_REGULAREXPRESSION_ALPHABETNUMERICWITHOUTTAGEMOJI: string = "^\\S[a-zA-Z0-9.,/\\s]+$";

	//#endregion


	//#region LANGUAGE_CULTURE

	static STRING_CULTURE_INDONESIAN: string = "id-ID";
	static STRING_CULTURE_ENGLISH: string = "en-US";

	static STRING_LANGUAGE_INDONESIAN: string = "ID";
	static STRING_LANGUAGE_ENGLISH: string = "EN";

	//#endregion


	//#region BUILD ENVIRONMENT

	static STRING_VALUE_BUILD_DEVELOPMENT = "Development";
	static STRING_VALUE_BUILD_STAGING = "Staging";
	static STRING_VALUE_BUILD_PRODUCTION = "Production";

	//#endregion


	//#region CHARACTER

	static STRING_CHARACTER_SEPARATOR_DATE_VIEW: string = "/";
	static STRING_CHARACTER_SEPARATOR_DATE_SERVER: string = "-";
	static STRING_CHARACTER_SEPARATOR_DATETIME_SERVER: string = "'T'";
	static STRING_CHARACTER_SEPARATOR_TIME_VIEW: string = ":";
	static STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW: string = "Z";
	static STRING_CHARACTER_SEPARATOR_DECIMAL: string = ";";
	static STRING_CHARACTER_SEPARATOR_THOUSAND: string = ".";
	static STRING_CHARACTER_DASH: string = " ";
	static STRING_CHARACTER_EMPTY: string = "-";

	static STRING_CHARACTER_SEPARATOR_FORMAT: string = ".";
	static STRING_CHARACTER_SEPARATOR_DIRECTORY: string = "/";
	static STRING_CHARACTER_SEPARATOR_FILENAME: string = "_";

	//#endregion


	//#region TAG

	static STRING_TAG_VERIFY_IDENTITYCARD: string = "PRODUCTASRI_";
	static STRING_TAG_VERIFY_SELFIEIDENTITYCARD: string = "PRODUCTOTO_";

	//#endregion


	//#region DATE

	static STRING_DATE_SERVER_DEFAULT: string = "0000" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_DATE_SERVER + "00";
	static STRING_TIME_SERVER_DEFAULT: string = "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + "00" + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_DEFAULT: string = StringConstant.STRING_DATE_SERVER_DEFAULT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_DEFAULT;
	static NUMBER_TIME_SERVER_COMPONENT: number = 3;

	static STRING_DATE_SERVER_FORMAT: string = "yyyy-MM-dd";
	static STRING_TIME_SERVER_FORMAT: string = "HH:mm:ss";
	static STRING_DATETIME_SERVER_FORMAT: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_TIMEZONE_VIEW;
	static STRING_DATETIME_SERVER_FORMATWITHOUTTIMEZONE: string = StringConstant.STRING_DATE_SERVER_FORMAT + StringConstant.STRING_CHARACTER_SEPARATOR_DATETIME_SERVER + StringConstant.STRING_TIME_SERVER_FORMAT;

	static STRING_DATE_VIEW_FORMAT: string = "dd/MM/yyyy";
	static STRING_TIME_VIEW_FORMAT: string = "HH:mm:ss";
	static STRING_TIME_VIEW_FORMATSHORT: string = "HH:mm";
	static STRING_DATETIME_VIEW_FORMAT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMAT;
	static STRING_DATETIME_VIEW_FORMATSHORT: string = StringConstant.STRING_DATE_VIEW_FORMAT + " " + StringConstant.STRING_TIME_VIEW_FORMATSHORT;
	static STRING_TIMEZONE_VIEW_FORMAT: string = "GMT+07:00";

	static STRING_CURRENCY_IDR_CODE: string = "IDR";
	static STRING_CURRENCY_IDR_PREFIX: string = "Rp ";
	static STRING_CURRENCY_THOUSANDS: string = "K. ";
	static STRING_CURRENCY_KILO: string = " Km";

	static STRING_DATE_SHORT_FORMAT = "d-M-yyyy";
	static STRING_DATE_MEDIUM_FORMAT = "d MMMM y";
	static STRING_DATE_LONG_FORMAT = "EEEE, d MMMM y";

	static STRING_TIME_SHORT_FORMAT = "HH:mm";
	static STRING_TIME_MEDIUM_FORMAT = "HH:mm:ss";
	static STRING_TIME_LONG_FORMAT = "HH:mm:ss z";

	static STRING_DATETIME_COMPLETE_FORMAT = "EEEE, d MMMM y | HH:mm:ss";

	//#endregion


	//#region KEY_VALUE

	static STRING_VALUE_YEAR_MINIMUM: string = "1900";
	static STRING_VALUE_APPLICATION_VERSION: string = "1.0.14";
	static STRING_VALUE_CLIENT_VERSION: string = "1.1:10";
	static STRING_VALUE_PRODUCT_ASRI: string = "0117";
	static STRING_VALUE_PRODUCT_OTO: string = "0206";
	static STRING_VALUE_PRODUCT_BICYCLE: string = "1216";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT: string = "1001";
	static STRING_VALUE_PACKAGE_ASRISILVER: string = "ASRI-01-V3";
	static STRING_VALUE_PACKAGE_ASRIGOLD: string = "ASRI-02-V3";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM: string = "ASRI-03-V3";
	static STRING_VALUE_PACKAGE_OTOBRONZE: string = "OTO-01-V3";
	static STRING_VALUE_PACKAGE_OTOSILVER: string = "OTO-02-V3";
	static STRING_VALUE_PACKAGE_OTOGOLD: string = "OTO-03-V3";
	static STRING_VALUE_PACKAGE_OTOPLATINUM: string = "OTO-04-V3";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM: string = "PA-BM";
	static STRING_VALUE_PRODUCT_ASRI_SYARIAH: string = "0164";
	static STRING_VALUE_PRODUCT_OTO_SYARIAH: string = "0256";
	static STRING_VALUE_PACKAGE_BICYCLESILVER: string = "1216-01-V3";
	static STRING_VALUE_PACKAGE_BICYCLEGOLD: string = "1216-02-V3";
	static STRING_VALUE_PACKAGE_BICYCLEPLATINUM: string = "1216-03-V3";
	static STRING_VALUE_PACKAGE_MYMOTORCYCLE: string = "OTO-07";
	static STRING_VALUE_PRODUCT_PERSONALACCIDENT_SYARIAH: string = "1051";
	static STRING_VALUE_PACKAGE_ASRISILVER_SYARIAH: string = "ASRI-01-S";
	static STRING_VALUE_PACKAGE_ASRIGOLD_SYARIAH: string = "ASRI-02-S";
	static STRING_VALUE_PACKAGE_ASRIPLATINUM_SYARIAH: string = "ASRI-03-S";
	static STRING_VALUE_PACKAGE_OTOBRONZE_SYARIAH: string = "OTO-01-S";
	static STRING_VALUE_PACKAGE_OTOSILVER_SYARIAH: string = "OTO-02-S";
	static STRING_VALUE_PACKAGE_OTOGOLD_SYARIAH: string = "OTO-03-S";
	static STRING_VALUE_PACKAGE_OTOPLATINUM_SYARIAH: string = "OTO-04-S";
	static STRING_VALUE_PACKAGE_PERSONALACCIDENTBM_SYARIAH: string = "PA-BM-S";

	static STRING_VALUE_FILENAME_CALCULATEPREMIUM: string = "calculatepremium";
	static STRING_VALUE_FILENAME_SPPA: string = "sppa";
	static STRING_VALUE_FILENAME_POLICYOUTSTANDING: string = "policyoutstanding";
	static STRING_VALUE_FILENAME_POLICYRENEWAL: string = "policyrenewal";

	static STRING_VALUE_PRINTJOB_PDFPREVIEW: string = "Anjani - PDF Preview";

	static STRING_VALUE_COUNTRYCODE_INDONESIA: string = "+62";
	static STRING_VALUE_COUNTRYCODE_INDONESIAWITHOUTPLUS: string = "62";
	static STRING_VALUE_COUNTRYCODE_VIETNAM: string = "+84";
	static STRING_VALUE_COUNTRYCODE_NEWJERSEY: string = "+856";
	static STRING_VALUE_COUNTRYCODE_SINGAPORE: string = "+65";
	static STRING_VALUE_COUNTRYCODE_MALAYSIA: string = "+60";
	static STRING_VALUE_COUNTRYCODE_BRUNEI: string = "+673";
	static STRING_VALUE_COUNTRYCODE_THAILAND: string = "+66";
	static STRING_VALUE_COUNTRYCODE_MYANMAR: string = "+95";
	static STRING_VALUE_COUNTRYCODE_PHILIPPINES: string = "+63";
	static STRING_VALUE_COUNTRYCODE_CAMBODIA: string = "+855";
	static STRING_VALUE_TELEPHONE_BRINS_PRIMARY: string = "(21)79170477";
	static STRING_VALUE_TELEPHONE_BRINS_SECONDARY: string = "(21)79170478";
	static STRING_VALUE_TELEPHONE_CALLCENTER: string = "14081";

	static ARRAY_VALUE_COUNTRYCODE: Array<string> = [
		StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIA, StringConstant.STRING_VALUE_COUNTRYCODE_VIETNAM ,StringConstant.STRING_VALUE_COUNTRYCODE_NEWJERSEY, StringConstant.STRING_VALUE_COUNTRYCODE_SINGAPORE,
		StringConstant.STRING_VALUE_COUNTRYCODE_MALAYSIA, StringConstant.STRING_VALUE_COUNTRYCODE_BRUNEI, StringConstant.STRING_VALUE_COUNTRYCODE_THAILAND, StringConstant.STRING_VALUE_COUNTRYCODE_MYANMAR,
		StringConstant.STRING_VALUE_COUNTRYCODE_PHILIPPINES, StringConstant.STRING_VALUE_COUNTRYCODE_CAMBODIA];

	static STRING_CHART_PIE_LEGENDPOSITION: string = "below";
	static STRING_CHART_BAR_LEGENDPOSITION: string = "right";

	static STRING_FIELD_EMAIL_BRINS: string = "@brins.co.id";
	static STRING_FIELD_EMAIL_TSI_BRINS: string = "tsi@brins.co.id";
	static STRING_FIELD_EMAIL_INFO_BRINS: string = "callcenter@brins.co.id";
	static STRING_FIELD_BRANCHCODE_HEADQUARTER: string = "115";

	//#endregion


	//#region IMAGE

	static STRING_FORMAT_IMAGE_JPG: string = "jpg";
	static STRING_FORMAT_COMPRESS_ZIP: string = "zip";
	static STRING_FORMAT_DOCUMENT_PDF: string = "pdf";
	static STRING_FORMAT_COMPRESS_RAR: string = "rar";
	static STRING_FORMAT_IMAGE_PNG: string = "png";
	static STRING_FORMAT_IMAGE_JPEG: string = "jpeg";
	static STRING_FORMAT_DOCUMENT_WORD: string = "doc";
	static STRING_FORMAT_DOCUMENT_WORDX: string = "docx";
	static STRING_FORMAT_DOCUMENT_EXCEL: string = "xls";
	static STRING_FORMAT_DOCUMENT_EXCELX: string = "xlsx";
	static STRING_FORMAT_DOCUMENT_CSV: string = "csv";

	static ARRAY_VALUE_FORMAT: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_DOCUMENT_PDF, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG, StringConstant.STRING_FORMAT_DOCUMENT_WORD, StringConstant.STRING_FORMAT_DOCUMENT_WORDX, StringConstant.STRING_FORMAT_DOCUMENT_EXCEL, StringConstant.STRING_FORMAT_DOCUMENT_EXCELX, StringConstant.STRING_FORMAT_DOCUMENT_CSV];

	static ARRAY_VALUE_IMAGE: Array<string> = [
		StringConstant.STRING_FORMAT_IMAGE_JPG, StringConstant.STRING_FORMAT_IMAGE_PNG, StringConstant.STRING_FORMAT_IMAGE_JPEG];

	//#endregion


	//#region CONVERTION

	static STRING_HASH_SHA256: string = "SHA-256";

	//#endregion


	//#region HTTP

	static STRING_HTTP_HEADER_AUTHORIZEDKEY: string = "AuthorizedKey";

	//#endregion


	//#region MODEL

	static STRING_MODULE_TICKETNUMBER: string = "Ticket.";

	//#endregion


	//#region ROUTING

	static STRING_ROUTING_KEY_TOKEN: string = "token";

	//#endregion


	//#region LOCAL STORAGE

	static STRING_LOCALSTORAGE_KEY_HANDSHAKEMODEL: string = "HandshakeModel";
	static STRING_LOCALSTORAGE_KEY_USERSESSION: string = "UserSession";
	static STRING_LOCALSTORAGE_KEY_BASEMODEL: string = "BaseModel";

	//#endregion


	//#region TITLE CARD LIST

	static STRING_VALUE_TITLECARDLIST_PURCHASE: string = "Beli";
	static STRING_VALUE_TITLECARDLIST_CLAIM: string = "Klaim";
	static STRING_VALUE_TITLECARDLIST_CUSTOMERCARE: string = "Bantuan";
	static STRING_VALUE_TITLECARDLIST_MELISHA: string = "Melisha";
	static STRING_VALUE_TITLECARDLIST_TICKETING: string = "Bantuan Layanan";
	static STRING_VALUE_TITLECARDLIST_NEARME: string = "Bengkel Terdekat";
	static STRING_VALUE_TITLECARDLIST_ASRI: string = "ASRI";
	static STRING_VALUE_TITLECARDLIST_OTO: string = "OTO";
	static STRING_VALUE_TITLECARDLIST_PERSONALACCIDENT: string = "DIRI";
	static STRING_VALUE_TITLECARDLIST_BICYCLE: string = "SEPEDA";

	//#endregion


	//#region SUBTITLE CARD LIST

	static STRING_VALUE_SUBTITLECARDLIST_PURCHASE: string = "Dapatkan perlindunganmu disini";
	static STRING_VALUE_SUBTITLECARDLIST_CLAIM: string = "Laporkan klaim Anda disini";
	static STRING_VALUE_SUBTITLECARDLIST_CUSTOMERCARE: string = "Butuh bantuan? cek layanan kami disini";
	static STRING_VALUE_SUBTITLECARDLIST_MELISHA: string = "Layanan chat agent dan chatbot BRINS";
	static STRING_VALUE_SUBTITLECARDLIST_TICKETING: string = "Layanan informasi, lapor keluhan dan pengaduan";
	static STRING_VALUE_SUBTITLECARDLIST_NEARME: string = "Cek daftar bengkel rekanan BRINS";
	static STRING_VALUE_SUBTITLECARDLIST_ASRI: string = "Lindungi properti dan tempat tinggal Anda";
	static STRING_VALUE_SUBTITLECARDLIST_OTO: string = "Melindungi kendaraan bermotor roda 4 (empat) Anda";
	static STRING_VALUE_SUBTITLECARDLIST_PERSONALACCIDENT: string = "Melindungi diri Anda dari risiko tidak terduga";
	static STRING_VALUE_SUBTITLECARDLIST_BICYCLE: string = "Perlindungan terhadap kerugian atas kerusakan pada sepeda";

	//#endregion


	//#region ICON CARD LIST

	static STRING_VALUE_ICONCARDLIST_PURCHASE: string = "../../assets/icons/sakina/icon_purchase.svg";
	static STRING_VALUE_ICONCARDLIST_CLAIM: string = "../../assets/icons/sakina/icon_claim.svg";
	static STRING_VALUE_ICONCARDLIST_CUSTOMERCARE: string = "../../assets/icons/sakina/icon_help.svg";
	static STRING_VALUE_ICONCARDLIST_MELISHA: string = "../../assets/icons/sakina/icon_melisha.svg";
	static STRING_VALUE_ICONCARDLIST_TICKETING: string = "../../assets/icons/sakina/icon_service.svg";
	static STRING_VALUE_ICONCARDLIST_NEARME: string = "../../assets/icons/sakina/icon_workshop.svg";
	static STRING_VALUE_ICONCARDLIST_ASRI: string = "../../assets/icons/sakina/icon_asri_yearly.svg";
	static STRING_VALUE_ICONCARDLIST_OTO: string = "../../assets/icons/sakina/icon_oto_yearly.svg";
	static STRING_VALUE_ICONCARDLIST_PERSONALACCIDENT: string = "../../assets/icons/sakina/icon_personal_accident_yearly.svg";
	static STRING_VALUE_ICONCARDLIST_BICYCLE: string = "../../assets/icons/sakina/icon_bicycle_yearly.svg";

	//#endregion


	//#region NAVIGATION TITLE

	static STRING_VALUE_TITLE_NAVIGATIONCUSTOMERCARE: string = "Bantuan";
	static STRING_VALUE_TITLE_NAVIGATIONTICKETING: string = "Bantuan Layanan";
	static STRING_VALUE_TITLE_NAVIGATIONPURCHASE: string = "Beli";
	static STRING_VALUE_TITLE_NAVIGATIONWORKSHOP: string = "Bengkel Terdekat";

	//#endregion


	//#region FOOTER

	static STRING_VALUE_TITLE_FOOTER: string = "Hak Cipta © PT. BRI Asuransi Indonesia";

	//#endregion


	//#region SOURCE

	static STRING_VALUE_SOURCE_NAVIGATIONCUSTOMERCARE: string = "../../../assets/icons/sakina/icon_help.svg";
	static STRING_VALUE_SOURCE_NAVIGATIONTICKETING: string = "../../../assets/icons/sakina/icon_ticketing.svg";
	static STRING_VALUE_SOURCE_NAVIGATIONPURCHASE: string = "../../../assets/icons/sakina/icon_purchase.svg";
	static STRING_VALUE_SOURCE_NAVIGATIONWORKSHOP: string = "../../../assets/icons/sakina/icon_workshop.svg";

	static STRING_VALUE_SOURCE_HEADERCLAIM: string = "../../../../assets/photos/sakina/image_header_claim.png";
	static STRING_VALUE_SOURCE_HEADERPERSONALACCIDENT: string = "../../../../assets/photos/sakina/image_header_personalaccident.png";
	static STRING_VALUE_SOURCE_HEADERASRI: string = "../../../../assets/photos/sakina/image_header_asri.png";
	static STRING_VALUE_SOURCE_HEADEROTO: string = "../../../../assets/photos/sakina/image_header_oto.png";
	static STRING_VALUE_SOURCE_HEADERBICYCLE: string = "../../../../assets/photos/sakina/image_header_bicycle.png";

	static STRING_VALUE_SOURCE_ICONCLAIM: string = "../../../../assets/icons/sakina/icon_claim.svg";
	static STRING_VALUE_SOURCE_ICONPURCHASEPERSONALACCIDENT: string = "../../../../assets/icons/sakina/icon_personal_accident_yearly.svg";
	static STRING_VALUE_SOURCE_ICONPURCHASEASRI: string = "../../../../assets/icons/sakina/icon_asri_yearly.svg";
	static STRING_VALUE_SOURCE_ICONPURCHASEOTO: string = "../../../../assets/icons/sakina/icon_oto_yearly.svg";
	static STRING_VALUE_SOURCE_ICONPURCHASEBICYCLE: string = "../../../../assets/icons/sakina/icon_bicycle_yearly.svg";

	static STRING_VALUE_SOURCE_IMAGEGENERALCARD: string = "../../../assets/icons/sakina/icon_background.svg";
	static STRING_VALUE_SOURCE_IMAGECARDPRODUCTARROWDOWN: string = "../../../../assets/inputs/sakina/input_dropdown.svg";
	static STRING_VALUE_SOURCE_IMAGECARDPRODUCTCLOSE: string = "../../../../assets/inputs/sakina/input_close.svg";
	static STRING_VALUE_SOURCE_IMAGECARDPRODUCTCHECKED: string = "../../../../assets/icons/sakina/icon_check.svg";
	static STRING_VALUE_SOURCE_IMAGECARDPRODUCTNOTCHECKED: string = "../../../../assets/icons/sakina/icon_uncheck.svg";
	static STRING_VALUE_SOURCE_IMAGENAVIGATIONBACK: string = "../../../../assets/icons/sakina/icon_back.svg";
	static STRING_VALUE_SOURCE_IMAGENAVIGATIONHOME: string = "../../../../assets/icons/sakina/icon_home.svg";

	static STRING_VALUE_SOURCE_IMAGEMAPSADDRESS: string = "../../../../assets/icons/sakina/icon_address.svg";
	static STRING_VALUE_SOURCE_IMAGEMAPSCONTACT: string = "../../../../assets/icons/sakina/icon_contact.svg";
	static STRING_VALUE_SOURCE_IMAGEMAPSCITY: string = "../../../../assets/icons/sakina/icon_city.svg";

	//#endregion


	//#region VALIDATION

	static STRING_VALIDATION_TICKET_EMPTY_NAME: string = "Nama tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_EMAIL: string = "Email tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_INCORRECT_EMAIL: string = "Format Email tidak sesuai!";
	static STRING_VALIDATION_TICKET_EMPTY_PHONENUMBER: string = "Nomor telepon boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TITLE: string = "Keluhan tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_TOPIC: string = "Topik tidak boleh kosong!";
	static STRING_VALIDATION_TICKET_EMPTY_DESCRIPTION: string = "Deskripsi tidak boleh kosong!";

	static STRING_VALIDATION_TICKET_FULLY_FILLED: string = "Tiket sudah terpenuhi!";

	//#endregion


	//#region PACKAGE NAME

	static STRING_VALUE_PACKAGE_BRONZE: string = "Bronze";
	static STRING_VALUE_PACKAGE_SILVER: string = "Silver";
	static STRING_VALUE_PACKAGE_GOLD: string = "Gold";
	static STRING_VALUE_PACKAGE_PLATINUM: string = "Platinum";

	//#endregion


	//#region SOURCE IMAGE URL

	static STRING_VALUE_SOURCE_ASRI: string = "asri";
	static STRING_VALUE_SOURCE_OTO: string = "oto";
	static STRING_VALUE_SOURCE_BICYCLE: string = "bicycle";
	static STRING_VALUE_SOURCE_PERSONALACCIDENT: string = "personalaccident";
	static STRING_VALUE_SOURCE_CLAIM: string = "claim";

	//#endregion


	//#region SOURCE URL BITLY

	static STRING_VALUE_SOURCE_BITLY: string = "bit.ly/";

	//#endregion
}

//#endregion

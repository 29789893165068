//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PurchaseComponent } from "./purchase/purchase.component";
import { AsriYearlyComponent } from "./asriyearly/asriyearly.component";
import { OtoYearlyComponent } from "./otoyearly/otoyearly.component";
import { PersonalAccidentYearlyComponent } from "./personalaccidentyearly/personalaccidentyearly.component";
import { BicycleYearlyComponent } from "./bicycleyearly/bicycleyearly.component";

//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		component: PurchaseComponent
	},
	{
		path: "asri",
		component: AsriYearlyComponent
	},
	{
		path: "oto",
		component: OtoYearlyComponent
	},
	{
		path: "personalaccident",
		component: PersonalAccidentYearlyComponent
	},
	{
		path: "bicycle",
		component: BicycleYearlyComponent
	},
];

//#endregion


//#region NGMODULE

@NgModule
(
	{
		imports:
	[
		RouterModule.forChild
		(
			arrayRoutes
		)
	],
		exports:
	[
		RouterModule
	]
	}
)

//#endregion


//#region CLASS

export class PurchaseRoutingModule
{

}

//#endregion

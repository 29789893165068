//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-purchase",
	templateUrl: "./purchase.component.html",
	styleUrls: ["./purchase.component.sass"]
})

//#endregion


//#region CLASS

export class PurchaseComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
  		super(serviceSession, router);

	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region NAVIGATION

	navigateToPurchaseProductAsri()
	{
		this._router.navigate(["home","purchase","asri"]);
	}

	navigateToPurchaseProductOto()
	{
		this._router.navigate(["home","purchase","oto"]);
	}

	navigateToPurchaseProductPersonalAccident()
	{
		this._router.navigate(["home","purchase","personalaccident"]);
	}

	navigateToPurchaseProductBicycle()
	{
		this._router.navigate(["home","purchase","bicycle"]);
	}

	//#endregion

}

//#endregion

//#region IMPORT

import { Component } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.sass"]
})

//#endregion


//#region CLASS

export class FooterComponent
{

	//#region DECLARATION

	public _stringConstant = StringConstant;

	//#endregion

}

//#endregion

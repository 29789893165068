//#region CLASS

import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { HeaderContentGalleryModel } from "./headercontentgallery.model";
import { ResponseModel } from "./response.model";

export class HeaderContentModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for header content model.
		Author: Dimas Dandy Aryarajendra Suprapto.
		Created on : Wednesday, 10 May 2023. 					Updated on : .
		Created by : Dimas Dandy Aryarajendra Suprapto.			Updated by : .
		Version : 1.0:1
	*/

	ID?: number;
	Token?: string;
	TitleIDN?: string;
	TitleEN?: string;
	SubTitleIDN?: string;
	SubTitleEN?: string;
	DescriptionIDN?: string;
	DescriptionEN?: string;
	HeaderType?: ENUM_HEADER_TYPE;
	DeviceType?: ENUM_DEVICE_TYPE;

	listModelHeaderGallery?: Array<HeaderContentGalleryModel>;

	/* Attribute - END */


	//#region VALIDATE

	validateInsertUpdateHeaderContent(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.MessageTitle = "Social Media";

		if(this.TitleIDN == null || this.TitleIDN === undefined || this.TitleIDN === "")
		{
			modelResponse.MessageContent = "Title (ID) tidak boleh kosong.";
		}
		else if(this.TitleEN == null || this.TitleEN === undefined || this.TitleEN === "")
		{
			modelResponse.MessageContent = "Title (EN) tidak boleh kosong.";
		}
		else if(this.HeaderType == null || this.HeaderType === undefined)
		{
			modelResponse.MessageContent = "Tipe Header tidak boleh kosong.";
		}
		else if(this.listModelHeaderGallery === undefined || this.listModelHeaderGallery?.length === 0)
		{
			modelResponse.MessageContent = "Gambar tidak boleh kosong.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion


	//#region SETTER

	setModelRequestInsertHeaderContent(modelHeaderRequest: HeaderContentModel): HeaderContentModel
	{
		const modelHeader = new HeaderContentModel();

		modelHeader.TitleIDN = modelHeaderRequest.TitleIDN;
		modelHeader.TitleEN = modelHeaderRequest.TitleEN;
		modelHeader.SubTitleIDN = modelHeaderRequest.SubTitleIDN;
		modelHeader.SubTitleEN = modelHeaderRequest.SubTitleEN;
		modelHeader.DescriptionIDN = modelHeaderRequest.DescriptionIDN;
		modelHeader.DescriptionEN = modelHeaderRequest.DescriptionEN;
		modelHeader.DeviceType = modelHeaderRequest.DeviceType;
		modelHeader.HeaderType = modelHeaderRequest.HeaderType;
		modelHeader.listModelHeaderGallery = modelHeaderRequest.listModelHeaderGallery;

		return modelHeader;
	}

	setModelRequestUpdateHeaderContent(modelHeaderRequest: HeaderContentModel): HeaderContentModel
	{
		const modelHeader = new HeaderContentModel();

		modelHeader.Token = modelHeaderRequest.Token;
		modelHeader.TitleIDN = modelHeaderRequest.TitleIDN;
		modelHeader.TitleEN = modelHeaderRequest.TitleEN;
		modelHeader.SubTitleIDN = modelHeaderRequest.SubTitleIDN;
		modelHeader.SubTitleEN = modelHeaderRequest.SubTitleEN;
		modelHeader.DescriptionIDN = modelHeaderRequest.DescriptionIDN;
		modelHeader.DescriptionEN = modelHeaderRequest.DescriptionEN;
		modelHeader.DeviceType = modelHeaderRequest.DeviceType;
		modelHeader.HeaderType = modelHeaderRequest.HeaderType;
		modelHeader.listModelHeaderGallery = modelHeaderRequest.listModelHeaderGallery;

		return modelHeader;
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Router } from "@angular/router";
import { ENUM_RESPONSE_STATE, ENUM_TOPIC_TYPE } from "src/app/constants/enum.constant";
import { TicketModel } from "src/app/models/ticket.model";
import { TicketService } from "src/app/services/ticket.service";
import { StringConstant } from "src/app/constants/string.constant";
import { RegexFunction } from "src/app/functions/regex.function";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { ResponseModel } from "src/app/models/response.model";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-ticketing",
	templateUrl: "./ticketing.component.html",
	styleUrls: ["./ticketing.component.sass"]
})

//#endregion


//#region CLASS

export class TicketingComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _stringPrefixCode: string = StringConstant.STRING_VALUE_COUNTRYCODE_INDONESIAWITHOUTPLUS;
	public _stringNumberPhone: number|undefined;

	public _enumTopicType = ENUM_TOPIC_TYPE;

	public _stringConstant = StringConstant;

	public _modelTicket: TicketModel;
	public _modelTicketRequest: TicketModel;

	public _functionRegex: RegexFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _serviceTicket: TicketService)
	{
  		super(serviceSession, router);

		this._modelTicket = new TicketModel();
		this._modelTicketRequest = new TicketModel();

		this._functionRegex = new RegexFunction();
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region WEBSERVICE

	public callInsertTicketForCustomerPublic(componentCurrent: TicketingComponent): void
	{
		this._modelTicketRequest.cleanForInsert();

		if(this._stringNumberPhone === undefined || this._stringNumberPhone === null)
		{
			this._modelTicketRequest.CustomerPhone = undefined;
		}
		else
		{
			this._modelTicketRequest.CustomerPhone = this._stringPrefixCode + this._stringNumberPhone?.toString();
		}

		this._modelTicketRequest.TicketNumber = undefined;
		const modelResponseValidation: ResponseModel = this._modelTicketRequest.validateTicketPublic();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._modelTicketRequest.cleanForInsert();
			componentCurrent._functionUserInterface.setLoadingProgress(1);

			this._serviceTicket.insertTicketForCustomerPublic
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						componentCurrent._functionUserInterface.updateLoadingProgress();
						modelResponse.setReturnContentWithTicketNumber();
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent.navigateToHome();
						});
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callInsertTicketForCustomerPublic(componentCurrent); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, this._modelTicketRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => { });
		}

	};

	//#endregion


	//#region NAVIGATION

	navigateToHome()
	{
		this._router.navigate(["home"]);
	}

	//#endregion

}

//#endregion

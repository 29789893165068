//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { StringConstant } from "src/app/constants/string.constant";
import { PackageModel } from "src/app/models/package.model";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-otoyearly",
	templateUrl: "./otoyearly.component.html",
	styleUrls: ["./otoyearly.component.sass"]
})

//#endregion


//#region CLASS

export class OtoYearlyComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelPackage: PackageModel;

	public _stringConstant = StringConstant;

	public _booleanShowQR: boolean;

	public _arrayModelPackage: Array<PackageModel> =
		[
			{
				Name: "Oto Bronze",
				Code: "OTO-01-V3",
				listCoverage :
				[
					{ Text: "Kerugian total", Covered : true },
					{ Text: "Kerugian sebagian", Covered : false },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Angin topan, badai, kerusakan akibat air", Covered : false },
					{ Text: "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang tercantum dalam polis (RSMDCC)", Covered : false },
					{ Text: "Terorisme dan sabotase", Covered : false },
					{ Text: "Gempa bumi, tsunami dan letusan gunung berapi", Covered : false },
					{ Text: "Kecelakaan pengemudi", Covered : false },
					{ Text: "Kecelakaan penumpang", Covered : false }
				]
			},
			{
				Name: "Oto Silver",
				Code: "OTO-02-V3",
				listCoverage :
				[
					{ Text: "Kerugian total", Covered : true },
					{ Text: "Kerugian sebagian", Covered : false },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Angin topan, badai, kerusakan akibat air", Covered : true },
					{ Text: "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang tercantum dalam polis (RSMDCC)", Covered : true },
					{ Text: "Terorisme dan sabotase", Covered : true },
					{ Text: "Gempa bumi, tsunami dan letusan gunung berapi", Covered : true },
					{ Text: "Kecelakaan pengemudi", Covered : true },
					{ Text: "Kecelakaan penumpang", Covered : true }
				]
			},
			{
				Name: "Oto Gold",
				Code: "OTO-03-V3",
				listCoverage :
				[
					{ Text: "Kerugian total", Covered : true },
					{ Text: "Kerugian sebagian", Covered : true },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Angin topan, badai, kerusakan akibat air", Covered : false },
					{ Text: "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang tercantum dalam polis (RSMDCC)", Covered : false },
					{ Text: "Terorisme dan sabotase", Covered : false },
					{ Text: "Gempa bumi, tsunami dan letusan gunung berapi", Covered : false },
					{ Text: "Kecelakaan pengemudi", Covered : false },
					{ Text: "Kecelakaan penumpang", Covered : false }
				]
			},
			{
				Name: "Oto Premium",
				Code: "OTO-04-V3",
				listCoverage :
				[
					{ Text: "Kerugian total", Covered : true },
					{ Text: "Kerugian sebagian", Covered : true },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Angin topan, badai, kerusakan akibat air", Covered : true },
					{ Text: "Kerusuhan, Pemogokan, Penghalangan Bekerja, Perbuatan Jahat, Huru-Hara, dan Penjarahan yang tercantum dalam polis (RSMDCC)", Covered : true },
					{ Text: "Terorisme dan sabotase", Covered : true },
					{ Text: "Gempa bumi, tsunami dan letusan gunung berapi", Covered : true },
					{ Text: "Kecelakaan pengemudi", Covered : true },
					{ Text: "Kecelakaan penumpang", Covered : true }
				]
			}
		];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);

		this._modelPackage = new PackageModel();

		this._booleanShowQR = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region NAVIGATION

	navigateBack()
	{
		if(this._booleanShowQR)
		{
			this._booleanShowQR = false;
		}
		else
		{
			this._location.back();
		}
	}

	//#endregion


	//#region SETTER

	setShowQR()
	{
		this._booleanShowQR = true;
	}

	//#endregion


	//#region GETTER

	public getSourcePackage(modelPackage: PackageModel): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOBRONZE)
		{
			return "../../../../../assets/icons/sakina/icon_oto_bronze.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOSILVER)
		{
			return "../../../../../assets/icons/sakina/icon_oto_silver.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOGOLD)
		{
			return "../../../../../assets/icons/sakina/icon_oto_gold.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM)
		{
			return "../../../../../assets/icons/sakina/icon_oto_platinum.svg";
		}
		else
		{
			return "";
		}
	}

	public getTitlePackage(modelPackage): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOBRONZE)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_BRONZE;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOSILVER)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_SILVER;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOGOLD)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_GOLD;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_OTOPLATINUM)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_PLATINUM;
		}
		else
		{
			return "";
		}
	}

	public getTypeByIndex(numberIndex: number): boolean
	{
		if(numberIndex === 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion
}

//#endregion

//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PublicGuardService } from "../services/guards/public.guard.service";
import { HomeComponent } from "./home/home.component";
import { ClaimComponent } from "./claim/claim.component";

//#endregion


//#region DECLARATION

const arrayRoute: Routes =
[
	{
		path: "",
		redirectTo: "/home",
		pathMatch: "full"
	},
	{
		path: "home",
		canActivate: [PublicGuardService],
		children:
		[
			{
				path: "",
				component: HomeComponent
			},
			{
				path: "purchase",
				loadChildren: () => import("./purchase/purchase.module").then(pageModule => pageModule.PurchaseModule)
			},
			{
				path: "customercare",
				loadChildren: () => import("./customercare/customercare.module").then(pageModule => pageModule.CustomerCareModule)
			},
			{
				path: "claim",
				component: ClaimComponent
			}
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports: [RouterModule.forRoot(arrayRoute)],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion

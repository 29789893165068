//#region

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-customercare",
	templateUrl: "./customercare.component.html",
	styleUrls: ["./customercare.component.sass"]
})

//#endregion


//#region CLASS

export class CustomerCareComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region NAVIGATION

	navigateToMelisha()
	{
		this._router.navigate(["home", "customercare", "melisha"]);
	}

	navigateToNearMe()
	{
		// window.location.href="https://development.brins.web.brinesia.app/home/customercare/workshop";
		this._router.navigate(["home", "customercare", "workshop"]);
	}

	navigateToTicketing()
	{
		this._router.navigate(["home","customercare", "ticketing"]);
	}

	//#endregion
}

//#endregion

//#region

import { Component, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { StringConstant } from "src/app/constants/string.constant";
import { NumberConstant } from "src/app/constants/number.constant";
import { HeaderService } from "src/app/services/header.service";
import { HeaderContentModel } from "src/app/models/headercontent.model";
import { ENUM_DEVICE_TYPE, ENUM_HEADER_TYPE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "../../constants/responsecode.constant";
import { ResponseModel } from "../../models/response.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.sass"]
})

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	public _stringConstant = StringConstant;

	public _numberConstant = NumberConstant;

	public _arrayImageSource: string[] = [];

	public _numberIndexHeaderCurrent: number;

	public _intervalIDSection: any;

	public _modelHeaderContent: HeaderContentModel;

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _elementRef: ElementRef, private _serviceHeader: HeaderService)
	{
		super(serviceSession, router);

		this._modelHeaderContent = new HeaderContentModel();

		this._numberIndexHeaderCurrent = 0;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		this.callSelectHeaderContentByAttributesForCustomer(this);
	}

	//#endregion


	//#region NAVIGATION

	navigateToPurchase()
	{
		this._router.navigate(["home", "purchase"]);
	}

	navigateToClaim()
	{
		this._router.navigate(["home", "claim"]);
	}

	navigateToCustomerCare()
	{
		this._router.navigate(["home", "customercare"]);
	}

	//#endregion


	//#region FUNCTION

	setIntervalHeader(): void
	{
		this._intervalIDSection = setInterval(() => { this.showContentNext(); }, this._numberConstant.NUMBER_INTERVAL_FIVE_SECONDS);
	}

	showContentNext(): void
	{
		this._numberIndexHeaderCurrent += NumberConstant.NUMBER_COMPARE_VALUE_ONE;

		if (this._numberIndexHeaderCurrent > this._arrayImageSource.length - NumberConstant.NUMBER_COMPARE_VALUE_ONE)
		{
			this._numberIndexHeaderCurrent = 0;
		}
		else
		{

		}
	}

	//#endregion


	//#region WEBSERVICE

	public callSelectHeaderContentByAttributesForCustomer(componentCurrent: HomeComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelHeaderContentRequest = new HeaderContentModel();
		modelHeaderContentRequest.DeviceType = ENUM_DEVICE_TYPE.KiosK;
		modelHeaderContentRequest.HeaderType = ENUM_HEADER_TYPE.Home;

		this._serviceHeader.selectHeaderContentByAttributesForCustomer
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelHeaderContent.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelHeaderContent.listModelHeaderGallery !== undefined)
						{
							for (const modelHeaderContentGallery of componentCurrent._modelHeaderContent.listModelHeaderGallery)
							{
								modelHeaderContentGallery.URL = componentCurrent._pipeConvertToBroadcastURL.transform(modelHeaderContentGallery.URL);
								componentCurrent._arrayImageSource.push(modelHeaderContentGallery.URL);
							}
						}

						if(componentCurrent._arrayImageSource.length > 1)
						{
							componentCurrent.setIntervalHeader();
						}

						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectHeaderContentByAttributesForCustomer(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelHeaderContentRequest);
	};

	//#endregion

}

//#endregion

//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PartnershipWorkshopCityModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for city of partnership workshop model, used for search by city from partnership workshop.
		Author: Eka Saputra.
		Created on : Monday, 18 April 2022. 				Updated on : Monday, 18 April 2022.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/

	ID?: number;

	BranchCode?: string;
	BranchName?: string;

	CityCode?: string;
	CityName?: string;

	ProvinceCode?: string;
	ProvinceName?: string;

	/* Attribute - END */

}

//#endregion
//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;

	//#endregion


	//#region URL WEBSITE SOCKET

	/*
	URL_USER_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_USER_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_USER;
	URLT_PRODUCT_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_PRODUCT_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_PRODUCT;
	URL_POLICY_WEBSITESOCKET: String = BuildConfig.SCHEMA_WEBSITESOCKET + BuildConfig.HOST_POLICY_API + StringConstant.STRING_CHARACTER_SEPARATOR_TIME_VIEW + BuildConfig.PORT_POLICY;
	*/

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForCustomer`;

	//#endregion


	//#region TICKET CONTROLLER

	static STRING_PATH_CONTROLLER_TICKET: string = "/Ticket";

	static STRING_URL_TICKET_INSERTTICKETFORCUSTOMERPUBLIC: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_TICKET}/insertTicketForCustomerPublic`;

	//#endregion


	//#region HEADERCONTENT

	static STRING_PATH_CONTROLLER_HEADERCONTENT: string = "/HeaderContent";

	static STRING_URL_HEADERCONTENT_SELECTHEADERCONTENTBYATTRIBUTESFORCUSTOMER: string = `${WebAddressConstant.STRING_URL_PRODUCT_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HEADERCONTENT}/selectHeaderContentByAttributesForCustomer`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP: string = "/PartnershipWorkshop";

	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOP: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshop`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopPublic`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributes`;
	static STRING_URL_PARTNERSHIPWORKSHOP_SELECTPARTNERSHIPWORKSHOPBYATTRIBUTESPUBLIC: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOP}/selectPartnershipWorkshopByAttributesPublic`;

	//#endregion


	//#region PARTNERSHIP WORSKSHOP CONTROLLER

	static STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY: string = "/PartnershipWorkshopCity";

	static STRING_URL_PARTNERSHIPWORKSHOPCITY_SELECTPARTNERSHIPWORKSHOPCITY: string = `${WebAddressConstant.STRING_URL_CLAIM_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PARTNERSHIPWORKSHOPCITY}/selectPartnershipWorkshopCity`;

	//#endregion
}

//#endregion

<div class="DivCardButton DivCardButtonPackage DivContainerCardButtonVertical" [ngClass]="{DivCardButtonFirstOfType: _booleanFirstOfType}"
(click)="showListCoverage()">
	<div class="DivContainerCardButtonHorizontal">
		<div class="DivContentImageLogo">
			<img class="ImagePurchase" src="{{_stringSource}}">
		</div>

		<div class="DivContentText">
			<label for="labelMenuTitle" class="LabelMenuTitle">{{_stringTitle}}</label>
			<ng-container *ngIf="_booleanSubTitle">
				<label for="labelMenuSubTitle" class="LabelMenuSubTitle">{{_stringSubTitle}}</label>
			</ng-container>
		</div>

		<div class="DivContentImageBackground">
			<ng-container *ngIf="_booleanShowExtend === false">
				<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGECARDPRODUCTARROWDOWN">
			</ng-container>
			<ng-container *ngIf="_booleanShowExtend === true">
				<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGECARDPRODUCTCLOSE">
			</ng-container>
		</div>
	</div>
	<ng-container *ngIf="_booleanShowExtend === true">
		<div class="DivDivider"></div>
		<div class="DivContainerCardButtonVertical">
			<div class="DivContainerProductCoverage">
				<ng-container *ngFor="let modelCoverage of _modelPackage.listCoverage">
					<div class="DivContainerCardButtonHorizontal FlexStart">
						<ng-container *ngIf="modelCoverage.Covered === true">
							<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGECARDPRODUCTCHECKED">
						</ng-container>
						<ng-container *ngIf="modelCoverage.Covered === false">
							<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGECARDPRODUCTNOTCHECKED">
						</ng-container>
						<p>{{modelCoverage.Text}}</p>
					</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>

//#region IMPORT

import { ENUM_WORKSHOP_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class PartnershipWorkshopModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for position model, used for link to division.
		Author: Eka Saputra.
		Created on : Saturday, 3 October 2021. 				Updated on : Saturday, 3 October 2021.
		Created by : Eka Saputra.							Updated by : Eka Saputra.
		Version : 1.0:1.
	*/


	ID?: number;
	Name?: string;
	Address?: string;
	PostalCode?: string;
	BranchCode?: string;
	BranchName?: string;
	CityName?: string;
	NPWP?: string;
	Telephone?: string;
	Fax?: string;
	Longitude?: any;
	Latitude?: any;
	Email?: string;
	MobilePhone?: string;
	Type?: ENUM_WORKSHOP_TYPE;

	/* Attribute - END */

}

//#endregion

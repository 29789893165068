//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CustomerCareComponent } from "./customercare/customercare.component";
import { TicketingComponent } from "./ticketing/ticketing.component";
import { MelishaComponent } from "./melisha/melisha.component";
import { WorkshopComponent } from "./workshop/workshop.component";


//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		component: CustomerCareComponent
	},
	{
		path: "ticketing",
		component: TicketingComponent
	},
	{
		path: "melisha",
		component: MelishaComponent
	},
	{
		path: "workshop",
		component: WorkshopComponent
	},
];

//#endregion


//#region NGMODULE

@NgModule
(
	{
		imports:
	[
		RouterModule.forChild
		(
			arrayRoutes
		)
	],
		exports:
	[
		RouterModule
	]
	}
)

//#endregion


//#region CLASS

export class CustomerCareRoutingModule
{

}

//#endregion

/* eslint-disable max-len */

//#region IMPORT

import { Component, ViewChildren, QueryList } from "@angular/core";
import { Router } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import { StringConstant } from "src/app/constants/string.constant";
import { MapInfoWindow, MapMarker } from "@angular/google-maps";
import { TableModel } from "src/app/models/bases/table.model";
import { PartnershipWorkshopModel } from "src/app/models/partnershipworkshop.model";
import { PartnershipWorkshopCityModel } from "src/app/models/partnershipworkshopcity.model";
import { PartnershipWorkshopService } from "src/app/services/partnershipworkshop.service";
import { PartnershipWorkshopCityService } from "src/app/services/partnershipworkshopcity.service";
import { ResponseModel } from "src/app/models/response.model";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-workshop",
	templateUrl: "./workshop.component.html",
	styleUrls: ["./workshop.component.sass"]
})

//#endregion


//#region CLASS

export class WorkshopComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	@ViewChildren(MapInfoWindow) infoWindowsView: QueryList<MapInfoWindow> | undefined;

	public _stringConstant = StringConstant;

	public _modelTable: TableModel;
	public _modelPartnershipWorkshop: PartnershipWorkshopModel;
	public _modelPartnershipWorkshopForResponsive: PartnershipWorkshopModel;
	public _modelPartnershipWorkshopCity: PartnershipWorkshopCityModel;

	public _arrayModelPartnershipWorkshop: Array<PartnershipWorkshopModel>;
	public _arrayModelPartnershipWorkshopForDropDown: Array<PartnershipWorkshopModel>;
	public _arrayModelPartnershipWorkshopCity: Array<PartnershipWorkshopCityModel>;

	public _booleanShowMaps: boolean;

	public _latitude: number;
	public _longitude: number;

	public _mapsStyle: google.maps.MapTypeStyle[];

	public mapOptions: google.maps.MapOptions =
		{
			center: { lat: this.latitude, lng: this.longitude },
			zoom : this.zoom,
			mapTypeId: google.maps.MapTypeId.ROADMAP
		};

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _servicePartnershipWorkshop: PartnershipWorkshopService, private _servicePartnershipWorkshopCity: PartnershipWorkshopCityService)
	{
  		super(serviceSession, router);

		this._modelTable = new TableModel();
		this._modelPartnershipWorkshopCity = new PartnershipWorkshopCityModel();

		this._modelPartnershipWorkshop = new PartnershipWorkshopModel();
		this._modelPartnershipWorkshopForResponsive = new PartnershipWorkshopModel();

		this._arrayModelPartnershipWorkshop = [];
		this._arrayModelPartnershipWorkshopForDropDown = [];
		this._arrayModelPartnershipWorkshopCity = [];

		this._latitude = this.latitude;
		this._longitude = this.longitude;

		this._booleanShowMaps = false;

		this._mapsStyle = [
			{ elementType: "geometry", stylers: [{ color: "#242f3e" }] },
			{ elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
			{ elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
			{
			  featureType: "administrative.locality",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#d59563" }],
			},
			{
			  featureType: "poi",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#d59563" }],
			},
			{
			  featureType: "poi.park",
			  elementType: "geometry",
			  stylers: [{ color: "#263c3f" }],
			},
			{
			  featureType: "poi.park",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#6b9a76" }],
			},
			{
			  featureType: "road",
			  elementType: "geometry",
			  stylers: [{ color: "#38414e" }],
			},
			{
			  featureType: "road",
			  elementType: "geometry.stroke",
			  stylers: [{ color: "#212a37" }],
			},
			{
			  featureType: "road",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#9ca5b3" }],
			},
			{
			  featureType: "road.highway",
			  elementType: "geometry",
			  stylers: [{ color: "#746855" }],
			},
			{
			  featureType: "road.highway",
			  elementType: "geometry.stroke",
			  stylers: [{ color: "#1f2835" }],
			},
			{
			  featureType: "road.highway",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#f3d19c" }],
			},
			{
			  featureType: "transit",
			  elementType: "geometry",
			  stylers: [{ color: "#2f3948" }],
			},
			{
			  featureType: "transit.station",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#d59563" }],
			},
			{
			  featureType: "water",
			  elementType: "geometry",
			  stylers: [{ color: "#17263c" }],
			},
			{
			  featureType: "water",
			  elementType: "labels.text.fill",
			  stylers: [{ color: "#515c6d" }],
			},
			{
			  featureType: "water",
			  elementType: "labels.text.stroke",
			  stylers: [{ color: "#17263c" }],
			},
		  ];
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
		this.callSelectPartnershipWorkshopCity(this, () => { this._functionUserInterface.updateLoadingProgress(); });
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectPartnershipWorkshopCity(componentCurrent: WorkshopComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(2);

		const modelTable: TableModel = new TableModel();
		modelTable.RowPerPage = 200;
		modelTable.Search = JSON.stringify(componentCurrent._modelPartnershipWorkshopCity);

		componentCurrent._servicePartnershipWorkshopCity.selectPartnershipWorkshopCity
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						modelTable.setModelFromString(modelResponse.Data);

						if (modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelPartnershipWorkshopCity = JSON.parse(modelTable.Result);

							componentCurrent._functionUserInterface.updateLoadingProgress();

							modelTable.Result = undefined;
							success();
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPartnershipWorkshopCity(componentCurrent, () => {}); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, modelTable);
	}

	private callSelectPartnershipWorkshopByAttributesPublic(componentCurrent: WorkshopComponent): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);
		componentCurrent._modelTable.Search = JSON.stringify(componentCurrent._modelPartnershipWorkshop);
		componentCurrent._servicePartnershipWorkshop.selectPartnershipWorkshopByAttributesPublic
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelPartnershipWorkshop = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._functionUserInterface.updateLoadingProgress();

							componentCurrent._modelTable.Result = undefined;
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectPartnershipWorkshopByAttributesPublic(componentCurrent); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	//#endregion


	//#region SETTER

	setPartnershipWorkshipByAttributes(stringCityName: string): void
	{
		this._modelPartnershipWorkshop = new PartnershipWorkshopModel();
		this._modelPartnershipWorkshop.CityName = stringCityName;
		this._modelTable.Page = 1;
		this._arrayModelPartnershipWorkshop = [];
		this._arrayModelPartnershipWorkshopForDropDown = [];
		this._booleanShowMaps = false;
		this.callSelectPartnershipWorkshopByAttributesPublic(this);
	}

	//#endregion


	//#region FUNCTION

	public showPartnershipWorkshopForResponsive(): void
	{
		if
		(
			(this._modelPartnershipWorkshopForResponsive.Latitude == null || this._modelPartnershipWorkshopForResponsive.Latitude === undefined || this._modelPartnershipWorkshopForResponsive.Latitude === 0 ) &&
			(this._modelPartnershipWorkshopForResponsive.Longitude == null || this._modelPartnershipWorkshopForResponsive.Longitude === undefined || this._modelPartnershipWorkshopForResponsive.Longitude === 0 )
		)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.MessageTitle = "Lokasi";
			modelResponse.MessageContent = "Maaf, lokasi tidak ditemukan";
			this._functionUserInterface.showDialogFromModelResponse(modelResponse, ()=>{});
			this._booleanShowMaps = false;
		}
		else
		{
			this._modelPartnershipWorkshop = this._modelPartnershipWorkshopForResponsive;
			this.latitude = this._modelPartnershipWorkshop.Latitude;
			this.longitude = this._modelPartnershipWorkshop.Longitude;
			this._booleanShowMaps = true;
			this.mapOptions =
			{
				center: { lat: this._modelPartnershipWorkshop.Latitude, lng: this._modelPartnershipWorkshop.Longitude },
				zoom : this.zoom,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				styles : this._mapsStyle
			};
		}
	}

	public openInfoWindow(marker: MapMarker, numberWindowIndex: number)
	{
		let numberCurrentIndex = 0;
		if ( this.infoWindowsView !== undefined)
		{
			this.infoWindowsView.forEach((window: MapInfoWindow) =>
			{
				if (numberWindowIndex === numberCurrentIndex)
				{
					window.open(marker);
					numberCurrentIndex++;
				}
				else
				{
					numberCurrentIndex++;
				}
			});
		}
		else
		{
		}
	}

	//#endregion

}

//#endregion

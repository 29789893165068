<div class="DivCardButton"
	[ngClass]="{DivCardButtonLastOfType: _booleanLastOfType,
		DivCardButtonPadding: !_booleanBackgroundCard}"
	(click)="onClick()">

	<div class="DivContentImageLogo">
		<img class="ImagePurchase" src="{{_stringSource}}">
	</div>

	<div class="DivContentText">
		<label for="labelMenuTitle" class="LabelMenuTitle">{{ _stringTitle }}</label>
		<label for="labelMenuSubTitle" class="LabelMenuSubTitle">{{ _stringSubTitle }}</label>
	</div>

	<ng-container *ngIf="_booleanBackgroundCard">
		<div class="DivContentImageBackground">
			<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGEGENERALCARD">
		</div>
	</ng-container>
</div>

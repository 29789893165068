//#region IMPORT

import { ENUM_RESPONSE_STATE, ENUM_SEVERITY_TYPE, ENUM_TICKET_STATUS, ENUM_TOPIC_TYPE } from "../constants/enum.constant";
import { StringConstant } from "../constants/string.constant";
import { BaseModel } from "./bases/base.model";
import { ResponseModel } from "./response.model";
import { UserModel } from "./user.model";

//#endregion


//#region CLASS

export class TicketModel extends BaseModel
{
	ID?: number;
	CustomerUserID?: number; // null
	Token?: string;
	TicketNumber?: string;

	Title?: string;
	Topic?: ENUM_TOPIC_TYPE;
	Description?: string;
	Status?: ENUM_TICKET_STATUS;
	SeverityType?: ENUM_SEVERITY_TYPE;
	Age?: number;
	Publicity?: boolean;
	DivisionID?: number;
	DivisionCode?: string;
	DivisionName?: string;
	CustomerName?: string;
	CustomerEmail?: string;
	CustomerPhone?: string;
	CustomerCoreID?: string;
	URL?: string;

	modelUser?: UserModel;
	modelCustomerUser?: UserModel;

	//#region CONSTRUCTOR

	constructor()
	{
		super();

		this.modelCustomerUser = new UserModel();
	}

	//#endregion


	//#region VALIDATION

	validateTicketPublic(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		const regEXEmail: RegExp = new RegExp(StringConstant.STRING_REGULAREXPRESSION_EMAIL);
		modelResponse.setForValidation("Ticket");

		if (this.CustomerName == null || this.CustomerName === undefined || this.CustomerName === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_NAME;
		}
		else if (this.Title == null || this.Title === undefined || this.Title === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_TITLE;
		}
		else if (this.Topic == null || this.Topic === undefined )
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_TOPIC;
		}
		else if (this.Description == null || this.Description === undefined || this.Description === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_DESCRIPTION;
		}
		else if (this.CustomerEmail == null || this.CustomerEmail === undefined || this.CustomerEmail === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_EMAIL;
		}
		else if (this.CustomerPhone == null || this.CustomerPhone === undefined || this.CustomerPhone === "")
		{
			modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_EMPTY_PHONENUMBER;
		}
		else
		{
			if (!regEXEmail.test(this.CustomerEmail))
			{
				modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_INCORRECT_EMAIL;
			}
			else
			{
				modelResponse.MessageContent = StringConstant.STRING_VALIDATION_TICKET_FULLY_FILLED;
				modelResponse.State = ENUM_RESPONSE_STATE.Success;
			}
		}

		return modelResponse;
	}

	//#endregion


	//#region FUNCTION

	cleanForInsert(): void
	{
		this.modelCustomerUser = undefined;
	}

	//#endregion

}

//#endregion

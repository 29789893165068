<div class="DivBodyGeneral">
	<header class="HeaderContainerProduct">
		<app-navigation
			[_booleanTitle]="false"
			[_booleanNavigationProduct]="true">
		</app-navigation>
		<img class="ImgBackground" [src]="_stringConstant.STRING_VALUE_SOURCE_HEADERCLAIM">
		<div class="DivContainerProduct">
			<img [src]="_stringConstant.STRING_VALUE_SOURCE_ICONCLAIM">
			<h1>Klaim</h1>
		</div>
	</header>

	<section class="SectionCardList SectionShowQR">
		<p>Scan QR dibawah ini</p>
		<img [src]="getSourceImageQRURL(_stringConstant.STRING_VALUE_SOURCE_CLAIM)">
		<p>atau salin link dibawah ini</p>
		<label>{{getStringURL(_stringConstant.STRING_VALUE_SOURCE_CLAIM, true)}}</label>
	</section>
	<app-footer></app-footer>
</div>

<div class="DivBodyGeneral">
	<header class="HeaderContainerProduct">
		<app-navigation
			[_stringSource]="_stringConstant.STRING_VALUE_SOURCE_NAVIGATIONPURCHASE"
			[_booleanTitle]="false"
			[_booleanNavigationProduct]="true"
			[_booleanCustomNavigateBack]="true"
			(_buttonClicked)="navigateBack()">
		</app-navigation>
		<img class="ImgBackground" [src]="_stringConstant.STRING_VALUE_SOURCE_HEADERPERSONALACCIDENT">
		<div class="DivContainerProduct">
			<img [src]="_stringConstant.STRING_VALUE_SOURCE_ICONPURCHASEPERSONALACCIDENT">
			<h1>DIRI</h1>
		</div>
	</header>

	<ng-container *ngIf="_booleanShowQR === false">
		<section class="SectionCardList SectionWithButton SectionProduct">
			<div class="DivFormVerticalContainer">
				<h2>Deskripsi</h2>
				<p>Melindungi diri Anda dari risiko tidak terduga yang diakibatkan oleh kecelakaan (yaitu: meninggal dunia, cacat tetap total atau cacat tetap sebagian dan biaya pengobatan) dan perluasan yang lainnya.</p>
				<h2>Paket</h2>
				<ng-container *ngFor="let modelPackage of _arrayModelPackage; let numberIndex = index">
					<app-cardproduct class="TransitionLineUpFourth"
						[_stringTitle]="getTitlePackage(modelPackage)"
						[_stringSubTitle]="modelPackage.Description"
						[_stringSource]="getSourcePackage(modelPackage)"
						[_modelPackage]="modelPackage"
						[_booleanFirstOfType]="getTypeByIndex(numberIndex)"
						[_booleanSubTitle]="true">
					</app-cardproduct>
				</ng-container>
			</div>
		</section>

		<app-stickbutton
			[_stringButtonTitle]="'Lanjutkan Pembelian'"
			[_stringCardTitle]="'Klik disini untuk membeli'"
			[_booleanCardTitle]="true"
			(_buttonClicked)="setShowQR()">
		</app-stickbutton>
	</ng-container>

	<ng-container *ngIf="_booleanShowQR === true">
		<section class="SectionCardList SectionShowQR">
			<p>Scan QR dibawah ini</p>
			<img [src]="getSourceImageQRURL(_stringConstant.STRING_VALUE_SOURCE_PERSONALACCIDENT)">
			<p>atau salin link dibawah ini</p>
			<label>{{getStringURL(_stringConstant.STRING_VALUE_SOURCE_PERSONALACCIDENT)}}</label>
		</section>
		<app-footer></app-footer>
	</ng-container>
</div>

//#region IMPORT

import { CoverageModel } from "./coverage.model";

//#endregion


//#region CLASS

export class PackageModel
{
	/*
		Attribute - START
		Description : Attribute for package model, used for package information and link to product.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 December 2020. 			Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						Updated by : Ibrahim Aziz.
		Version : 1.0:1.
	*/

	ID?: number;
	ProductID?: number;
	Name?: string;
	Description?: string;
	Code?: string;
	Token?: string;

	StartDate?: Date;
	ExpiredDate?: Date;
	Type?: number;
	ImageResourceID?: number;
	InformationPDFURL?: string;

	listCoverage?: Array<CoverageModel>;

	/* Attribute - END */
}

//#endregion

<router-outlet></router-outlet>
<div class="DivBodyGeneral">
	<header id="HeaderContainer" class="HeaderContainer Fade">
		<img [src]="_arrayImageSource[_numberIndexHeaderCurrent]" onerror="this.src='../../../assets/icons/sakina/icon_placeholder_failed.svg'">
		<div class="DivContainerOverlay"></div>
	</header>
	<section class="SectionCardList">
		<!-- MENU PURCHASE -->
		<app-card class="TransitionLineUpFirst"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_PURCHASE"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_PURCHASE"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_PURCHASE"
			[_booleanBackgroundCard]="true"
			(_buttonClicked)="navigateToPurchase()">
		</app-card>

		<!-- MENU CLAIM -->
		<app-card class="TransitionLineUpSecond"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_CLAIM"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_CLAIM"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_CLAIM"
			[_booleanBackgroundCard]="true"
			(_buttonClicked)="navigateToClaim()">
		</app-card>

		<!-- MENU CUSTOMERCARE -->
		<app-card class="TransitionLineUpThird"
			[_stringTitle]="_stringConstant.STRING_VALUE_TITLECARDLIST_CUSTOMERCARE"
			[_stringSubTitle]="_stringConstant.STRING_VALUE_SUBTITLECARDLIST_CUSTOMERCARE"
			[_stringSource]="_stringConstant.STRING_VALUE_ICONCARDLIST_CUSTOMERCARE"
			[_booleanBackgroundCard]="true"
			[_booleanLastOfType]="true"
			(_buttonClicked)="navigateToCustomerCare()">
		</app-card>
	</section>
	<app-footer></app-footer>
</div>

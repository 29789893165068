//#region IMPORT

import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { of, Observable } from "rxjs";
import { HandshakeModel } from "../../models/handshake.model";
import { CryptographyFunction } from "../../functions/cryptography.function";
import { UserInterfaceFunction } from "../../functions/userinterface.function";
import "../../functions/extension.function";
import { ResponseCodeConstant } from "../../constants/responsecode.constant";
import { ResponseModel } from "../../models/response.model";
import { UserSessionModel } from "../../models/usersession.model";
import { SessionService } from "../session.service";
import { HandshakeService } from "../handshake.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class PublicGuardService implements CanActivate
{
	//#region DECLARATION

	public _stringURL?: string;
	private _modelHandshakeSignIn: HandshakeModel;
	private _functionCryptography: CryptographyFunction;
	private _functionUserInterface: UserInterfaceFunction;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceHandshake: HandshakeService, private _router: Router, private _serviceSession: SessionService)
	{
		this._modelHandshakeSignIn = new HandshakeModel();

		_serviceSession._modelHandshakeSignIn.subscribe(
			{
				next: (modelHandshake: HandshakeModel) => { this._modelHandshakeSignIn = modelHandshake; },
				complete: () => { },
				error: () => { }
			});

		this._functionCryptography = new CryptographyFunction();
		this._functionUserInterface = new UserInterfaceFunction();
	}

	//#endregion


	//#region

	canActivate(): Observable<boolean>
	{
		try
		{
			let booleanToken: boolean = false;

			if (this.validateModelHandshake(this._modelHandshakeSignIn))
			{
				booleanToken = true;
			}
			else
			{
				const modelHandshakeFromLocalStorage: HandshakeModel | null = this._serviceSession.getModelHandshakeSignInFromLocalStorage();

				if (modelHandshakeFromLocalStorage != null)
				{
					if (this.validateModelHandshake(modelHandshakeFromLocalStorage))
					{
						this._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeFromLocalStorage);

						booleanToken = true;
					}
					else
					{
					}
				}
				else
				{

				}
			}

			if (booleanToken)
			{
				const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

				if (modelUserSession != null)
				{
					if (modelUserSession.UserToken != null || modelUserSession.UserToken === undefined || modelUserSession.UserToken === "" )
					{
						this._serviceSession.setUserSession(modelUserSession);

						return of(true);
					}
					else
					{
						return this.callRefreshToken();
					}
				}
				else
				{
					return this.callRefreshToken();
				}
			}
			else
			{
				return this.callGenerateToken();
			}
		}
		catch (error)
		{
			const modelResponse: ResponseModel = new ResponseModel();
			modelResponse.setSessionExpired();
			modelResponse.MessageContent = (error as Error).message;

			this._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

			return this.callGenerateToken();
		}
	}

	//#endregion


	//#region CALL WEBSITE SERVICE

	private callGenerateToken(): Observable<boolean>
	{
		const componentCurrent: PublicGuardService = this;
		const modelHandshakeRSA: HandshakeModel = this._functionCryptography.generateKey();

		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.RequestKey = modelHandshakeRSA.RequestKey;
		modelHandshake.removeHeaderForHandshake();

		return this._serviceHandshake.generateToken
		(
			{
				success(modelResponse, stringAuthorizedKey): boolean
				{
					if (modelResponse.Data !== undefined && modelHandshakeRSA.ResponseKey !== undefined)
					{
						const stringModelHandshake: string = componentCurrent._functionCryptography.decryptAsymmetric(modelResponse.Data, modelHandshakeRSA.ResponseKey);
						const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
						modelHandshakeResponse.setModelFromString(stringModelHandshake);
						modelHandshakeResponse.Token = stringAuthorizedKey;

						componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);

						return true;
					}
					else
					{
						return false;
					}
				},
				fail(modelResponse): Observable<boolean>
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { });

					return componentCurrent.callGenerateToken();
				},
				failNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					return false;
				},
				signOut(modelResponse): Observable<boolean>
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					return of(false);
				},
				signOutNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });

					return false;
				}
			}, modelHandshake
		);
	}

	private callRefreshToken(): Observable<boolean>
	{
		const componentCurrent: PublicGuardService = this;
		const modelHandshake: HandshakeModel = new HandshakeModel();
		modelHandshake.Token = this._modelHandshakeSignIn.Token;

		return this._serviceHandshake.refreshToken
		(
			{
				success(modelResponse): boolean
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							const modelHandshakeResponse: HandshakeModel = new HandshakeModel();
							modelHandshakeResponse.setModelFromString(modelResponse.Data);

							componentCurrent._serviceSession.setModelHandshakeSignInToLocalStorage(modelHandshakeResponse);
							return true;
						}
						else
						{
							return false;
						}
					}
					else
					{
						return false;
					}
				},
				fail(modelResponse): Observable<boolean>
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { });

					return componentCurrent.callGenerateToken();
				},
				failNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { });

					return false;
				},
				signOut(modelResponse): Observable<boolean>
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });

					return of(false);
				},
				signOutNonObservable(modelResponse): boolean
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });

					return false;
				}
			}, modelHandshake
		);
	}

	//#endregion


	//#region FUNCTION

	private validateModelHandshake(modelHandshake: HandshakeModel): boolean
	{
		if (modelHandshake == null || modelHandshake === undefined)
		{
			return false;
		}
		else
		{
			if (modelHandshake.Token !== undefined && modelHandshake.Token.validateEmpty())
			{
				return true;
			}
			else
			{
				return false;
			}
		}
	}

	private signOut(): void
	{
		this._serviceSession.clearKey();
		this._serviceSession.clearSession();
	}

	//#endregion
}

//#endregion

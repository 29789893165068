//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { PackageModel } from "src/app/models/package.model";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-asriyearly",
	templateUrl: "./asriyearly.component.html",
	styleUrls: ["./asriyearly.component.sass"]
})

//#endregion


//#region CLASS

export class AsriYearlyComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelPackage: PackageModel;

	public _stringConstant = StringConstant;
	public _stringImageQRURL: string;

	public _booleanShowQR: boolean;

	public _arrayModelPackage: Array<PackageModel> =
		[
			{
				Name: "Asri Silver",
				Code: "ASRI-01-V3",
				listCoverage :
				[
					{ Text: "Kebakaran, petir, ledakan, kejatuhan pesawat terbang, asap", Covered : true },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : false },
					{ Text: "Banjir, angin topan, badai, kerusakan akibat air", Covered : false},
					{ Text: "Kerusuhan, pemogokan, penghalangan bekerja, perbuatan jahat, huru-hara, dan penjarahan yang tercantum dalam polis (RSMDCC)", Covered : false },
					{ Text: "Terorisme dan sabotase", Covered : false },
					{ Text: "Kebongkaran", Covered : false },
					{ Text: "Santunan duka meninggal dunia dan cacat tetap akibat kecelakaan", Covered : false },
					{ Text: "Santunan biaya pengobatan atau perawatan medis", Covered : false },
					{ Text: "Bantuan sewa rumah tinggal", Covered : false },
					{ Text: "Gempa bumi, tsunami, letusan gunung berapi", Covered : false }
				]
			},
			{
				Name: "Asri Gold",
				Code: "ASRI-02-V3",
				listCoverage :
				[
					{ Text: "Kebakaran, petir, ledakan, kejatuhan pesawat terbang, asap", Covered : true },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Banjir, angin topan, badai, kerusakan akibat air", Covered : true },
					{ Text: "Kerusuhan, pemogokan, penghalangan bekerja, perbuatan jahat, huru-hara, dan penjarahan yang tercantum dalam polis (RSMDCC)", Covered : true },
					{ Text: "Terorisme dan sabotase", Covered : true },
					{ Text: "Kebongkaran", Covered : true },
					{ Text: "Santunan duka meninggal dunia dan cacat tetap akibat kecelakaan", Covered : true },
					{ Text: "Santunan biaya pengobatan atau perawatan medis", Covered : true },
					{ Text: "Bantuan sewa rumah tinggal", Covered : true },
					{ Text: "Gempa bumi, tsunami, letusan gunung berapi", Covered : false }
				]
			},
			{
				Name: "Asri Platinum",
				Code: "ASRI-03-V3",
				listCoverage :
				[
					{ Text: "Kebakaran, petir, ledakan, kejatuhan pesawat terbang, asap", Covered : true },
					{ Text: "Tanggung jawab hukum pihak ketiga", Covered : true },
					{ Text: "Banjir, angin topan, badai, kerusakan akibat air", Covered : true },
					{ Text: "Kerusuhan, pemogokan, penghalangan bekerja, perbuatan jahat, huru-hara, dan penjarahan yang tercantum dalam polis (RSMDCC)", Covered : true },
					{ Text: "Terorisme dan sabotase", Covered : true },
					{ Text: "Kebongkaran", Covered : true },
					{ Text: "Santunan duka meninggal dunia dan cacat tetap akibat kecelakaan", Covered : true },
					{ Text: "Santunan biaya pengobatan atau perawatan medis", Covered : true },
					{ Text: "Bantuan sewa rumah tinggal", Covered : true },
					{ Text: "Gempa bumi, tsunami, letusan gunung berapi", Covered : true }
				]
			}
		];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);

		this._modelPackage = new PackageModel();

		this._booleanShowQR = false;

		this._stringImageQRURL = "";
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region NAVIGATION

	navigateBack()
	{
		if(this._booleanShowQR)
		{
			this._booleanShowQR = false;
		}
		else
		{
			this._location.back();
		}
	}

	//#endregion


	//#region SETTER

	setShowQR()
	{
		this._booleanShowQR = true;
	}

	//#endregion


	//#region GETTER

	public getSourcePackage(modelPackage: PackageModel): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRISILVER)
		{
			return "../../../../../assets/icons/sakina/icon_asri_silver.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRIGOLD)
		{
			return "../../../../../assets/icons/sakina/icon_asri_gold.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM)
		{
			return "../../../../../assets/icons/sakina/icon_asri_platinum.svg";
		}
		else
		{
			return "";
		}
	}

	public getTitlePackage(modelPackage): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRISILVER)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_SILVER;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRIGOLD)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_GOLD;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_ASRIPLATINUM)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_PLATINUM;
		}
		else
		{
			return "";
		}
	}

	public getTypeByIndex(numberIndex: number): boolean
	{
		if(numberIndex === 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion

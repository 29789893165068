<div class="DivNavigation" [ngClass]="{DivNavigationProduct: _booleanNavigationProduct}">
	<ng-container *ngIf="_booleanCustomNavigateBack === false">
		<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGENAVIGATIONBACK" (click)="navigateToBack()">
	</ng-container>

	<ng-container *ngIf="_booleanCustomNavigateBack === true">
		<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGENAVIGATIONBACK" (click)="onClick()">
	</ng-container>

	<div class="DivContainerTitle" [ngClass]="{DivHideTitle: !_booleanTitle}">
		<img src="{{ _stringSource }}">
		<p>{{ _stringTitle }}</p>
	</div>

	<img [src]="_stringConstant.STRING_VALUE_SOURCE_IMAGENAVIGATIONHOME" (click)="navigateToHome()">
</div>

//#region IMPORT

import { Component } from "@angular/core";
import { SessionService } from "src/app/services/session.service";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { PackageModel } from "src/app/models/package.model";
import { StringConstant } from "src/app/constants/string.constant";
import { BaseAuthourizeComponent } from "../../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-bicycleyearly",
	templateUrl: "./bicycleyearly.component.html",
	styleUrls: ["./bicycleyearly.component.sass"]
})

//#endregion


//#region CLASS

export class BicycleYearlyComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _modelPackage: PackageModel;

	public _stringConstant = StringConstant;

	public _booleanShowQR: boolean;

	public _arrayModelPackage: Array<PackageModel> =
		[
			{
				Code: "1216-01-V3",
				Name: "SILVER",
				Description: "../../../assets/icons/sakina/package/icon_sepeda_silver.svg",
				listCoverage :
			[
				{ Text: "Kerugian total", Covered : true },
				{ Text: "Pencurian dengan kekerasan", Covered : true },
				{ Text: "Bencana alam", Covered : false },
				{ Text: "Kerusuhan dan huru hara", Covered : false },
				{ Text: "Terorisme dan sabotase", Covered : false }
			]
			},
			{
				Code: "1216-02-V3",
				Name: "GOLD",
				Description: "../../../assets/icons/sakina/package/icon_sepeda_platinum.svg",
				listCoverage :
			[
				{ Text: "Kerugian total", Covered : true },
				{ Text: "Pencurian dengan kekerasan", Covered : true },
				{ Text: "Bencana alam", Covered : true },
				{ Text: "Kerusuhan dan huru hara", Covered : false },
				{ Text: "Terorisme dan sabotase", Covered : false }
			]
			},
			{
				Code: "1216-03-V3",
				Name: "PLATINUM",
				Description: "../../../assets/icons/sakina/package/icon_sepeda_platinum.svg",
				listCoverage :
			[
				{ Text: "Kerugian total", Covered : true },
				{ Text: "Pencurian dengan kekerasan", Covered : true },
				{ Text: "Bencana alam", Covered : true },
				{ Text: "Kerusuhan dan huru hara", Covered : true },
				{ Text: "Terorisme dan sabotase", Covered : true }
			]
			}
		];

	//#endregion


	//#region CONSTRUCTOR

	constructor(serviceSession: SessionService, router: Router, private _location: Location)
	{
		super(serviceSession, router);

		this._modelPackage = new PackageModel();

		this._booleanShowQR = false;
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{

	}

	//#endregion


	//#region NAVIGATION

	navigateBack()
	{
		if(this._booleanShowQR)
		{
			this._booleanShowQR = false;
		}
		else
		{
			this._location.back();
		}
	}

	//#endregion


	//#region SETTER

	setShowQR()
	{
		this._booleanShowQR = true;
	}

	//#endregion


	//#region GETTER

	public getSourcePackage(modelPackage: PackageModel): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER)
		{
			return "../../../../../assets/icons/sakina/icon_bicycle_silver.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD)
		{
			return "../../../../../assets/icons/sakina/icon_bicycle_gold.svg";
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM)
		{
			return "../../../../../assets/icons/sakina/icon_bicycle_platinum.svg";
		}
		else
		{
			return "";
		}
	}

	public getTitlePackage(modelPackage): string
	{
		if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLESILVER)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_SILVER;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLEGOLD)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_GOLD;
		}
		else if(modelPackage.Code === StringConstant.STRING_VALUE_PACKAGE_BICYCLEPLATINUM)
		{
			return this._stringConstant.STRING_VALUE_PACKAGE_PLATINUM;
		}
		else
		{
			return "";
		}
	}

	public getTypeByIndex(numberIndex: number): boolean
	{
		if(numberIndex === 0)
		{
			return true;
		}
		else
		{
			return false;
		}
	}

	//#endregion

}

//#endregion

//#region IMPORT

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StringConstant } from "src/app/constants/string.constant";
import { PackageModel } from "src/app/models/package.model";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-cardproduct",
	templateUrl: "./cardproduct.component.html",
	styleUrls: ["./cardproduct.component.sass"]
})

//#endregion


//#region CLASS

export class CardproductComponent implements OnInit
{
	//#region DECLARATION

	@Input() _stringTitle: string | undefined;
	@Input() _stringSubTitle: string | undefined;
	@Input() _stringSource: string;

	@Input() _booleanShowExtend: boolean;
	@Input() _booleanFirstOfType: boolean | undefined;
	@Input() _booleanSubTitle: boolean;

	@Input() _modelPackage: PackageModel;

	@Output() _buttonClicked = new EventEmitter<string>();

	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{
		this._stringTitle = "";
		this._stringSubTitle = "";
		this._stringSource = "";

		this._booleanShowExtend = false;
		this._booleanFirstOfType = false;
		this._booleanSubTitle = false;

		this._modelPackage = new PackageModel();
	}

	//#endregion


	//#region INIT

	ngOnInit(): void
	{
	}

	//#endregion


	//#region FUNCTION

	onClick()
	{
		this._buttonClicked.emit("Button clicked in child component");
	}

	showListCoverage()
	{
		this._booleanShowExtend = !this._booleanShowExtend;
	}

	  //#endregion

}

//#endregion
